.nav_wrapper {
    position: absolute;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    z-index: 101;
    width: calc(100% - 40px);
    img {
       width: 90px;
    }

    .menu-active & {
        z-index: 102;
    }
}

.ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;


    .menu-active & {
        z-index: 102;
    }
}
.hamRotate {
    .menu-active & {
        transform: rotate(45deg);
    }
}
  
.line {
    fill:none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: rgba(black, 0.2);
    stroke-width:3;
    stroke-linecap:round;

    .menu-active & {
        stroke: rgba(black, 0.2);
    }
}
.ham .top {
    stroke-dasharray: 40 121;
}
.ham .bottom {
    stroke-dasharray: 40 121;
}
.ham .top {
    .menu-active & {
        stroke-dashoffset: -68px;
    }
}
.ham .bottom {
    .menu-active & {
        stroke-dashoffset: -68px;
    }
}