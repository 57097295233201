/* SASS partial for grunt-webfont, autogenerated, do not edit! */
@font-face {
  font-family: "icons";
  src: url('../fonts/icons.eot');
  src: url('../fonts/icons.eot?#iefix') format('eot'),
    url('../fonts/icons.woff') format('woff'),
    url('../fonts/icons.ttf') format('truetype'),
    url('../fonts/icons.svg#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin iconcss {
	font-family:"icons";
	vertical-align:middle;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:optimizeLegibility;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}

.iconcss {
	@include iconcss();
}

.icon-arrow-left-thin:before { content: "\EA01" }
.icon-arrow-left:before { content: "\EA02" }
.icon-arrow-right-thin:before { content: "\EA03" }
.icon-arrow-right:before { content: "\EA04" }
.icon-brochure:before { content: "\EA05" }
.icon-card:before { content: "\EA06" }
.icon-circle-empty:before { content: "\EA07" }
.icon-circle-full:before { content: "\EA08" }
.icon-circle-half:before { content: "\EA09" }
.icon-close:before { content: "\EA0A" }
.icon-code:before { content: "\EA0B" }
.icon-eye:before { content: "\EA0C" }
.icon-flash:before { content: "\EA0D" }
.icon-graphic-design:before { content: "\EA0E" }
.icon-home:before { content: "\EA0F" }
.icon-large-format:before { content: "\EA10" }
.icon-mail:before { content: "\EA11" }
.icon-package:before { content: "\EA12" }
.icon-photography:before { content: "\EA13" }
.icon-responsive:before { content: "\EA14" }
.icon-ux:before { content: "\EA15" }
.icon-video:before { content: "\EA16" }
.icon-desktop:before { content: "\EA17" }
.icon-laptop:before { content: "\EA18" }
.icon-mobile:before { content: "\EA19" }
.icon-tablet:before { content: "\EA1A" }
.icon-tablet-horizontal:before { content: "\EA1B" }
.icon-lock:before { content: "\EA1C" }
.icon-logo:before { content: "\EA1D" }
.icon-chrome:before { content: "\EA1E" }
.icon-firefox:before { content: "\EA1F" }
.icon-safari:before { content: "\EA20" }
.icon-play:before { content: "\EA21" }


@mixin icon($icon, $font-size:false, $extend-base:true) {
	@extend .icon-#{$icon}:before;
	@if($font-size) {
		font-size: $font-size;
  		font-family: "icons";
	}
	@if($extend-base) {
		@extend .iconcss; 
	}
}