.section_top, .section_bottom {
	content: '';
	width: 100%;
	height: 220px;
	z-index: 3;
	bottom: 0;
	position: absolute;
}

.section_top {
	top: 0;
	background: -moz-linear-gradient(top, rgba($greyBG,1) 0%, rgba($greyBG,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba($greyBG,1) 0%,rgba($greyBG,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba($greyBG,1) 0%,rgba($greyBG,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

.section_bottom {
	bottom: 0;
	background: -moz-linear-gradient(bottom, rgba($greyBG,1) 0%, rgba($greyBG,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(bottom, rgba($greyBG,1) 0%,rgba($greyBG,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to top, rgba($greyBG,1) 0%,rgba($greyBG,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

.thumb_container {
	height: 100vh;
	width: 100%;
    position: relative;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		position: absolute;

		&.color_thumb {
			opacity: 0;
			z-index: 2;
		}
	}
}

.heading {
    display: flex;
    align-self: flex-end;
}

.concept-flag {
    border-radius: 2px;
    background: tomato;
    height: 34px;
    padding: 10px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    margin-left: 20px;
    position: relative;

    &:after {
        transform: rotate(35deg);
        -o-transform: rotate(35deg);
        -ms-transform: rotate(35deg);
        -webkit-transform: rotate(35deg);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 20px solid tomato;
        content: '';
        position: absolute;
        left: -2px;
        bottom: -12px;
    }
}

.portfolio_item_text_wrapper {
	position: absolute;
	bottom: 80px;
	left: 80px;
	z-index: 4;
	max-width: 820px;
	padding: 20px;
	color: white;
	overflow: hidden;
	background: rgba(black, 0.4);
    opacity: 0;
    transform: translateY(20px);
    -moz-transform: translateY(20px);
    -o-transform: translateY(20px);
    -webkit-transform: translateY(20px);
    transition: .2s ease-in-out .2s;
    -o-transition: .2s ease-in-out .2s;
    -moz-transition: .2s ease-in-out .2s;
    -webkit-transition: .2s ease-in-out .2s;

	button {
		border: 1px solid $outfront;
        color: white;
        padding: 10px 20px;
        background: $outfront;
		letter-spacing: 1px;
        border-radius: 1px;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        opacity: 0;
        transform: translateY(20px);
        -moz-transform: translateY(20px);
        -o-transform: translateY(20px);
        -webkit-transform: translateY(20px);
        transition: .2s ease-in-out .7s;
        -o-transition: .2s ease-in-out .7s;
        -moz-transition: .2s ease-in-out .7s;
        -webkit-transition: .2s ease-in-out .7s;
        
        #lorax & {
            border-color: $lorax;
            background: $lorax;
        }

        #chiller & {
            background: $chillerRed;
            border-color: $chillerRed;
        }

        #nbc & {
            background: $nbcux;
            border-color: $nbcux;
        }

        #disney & {
            background: $disney;
            border-color: $disney;
        }

        #jurassic & {
            background: $jurassicBlue;
            border-color: $jurassicBlue;
        }

        #cosgrove & {
            background: $cosgrove;
            border-color: $cosgrove;
        }

        #softheon & {
            background: $softheon;
            border-color: $softheon;
        }

        #calle & {
            background: $calle;
            border-color: $calle;
        }

        #favela & {
            background: $favela;
            border-color: $favela;
        }

        #pcc & {
            background: $pcc;
            border-color: $pcc;
        }

        #parotees & {
            background: $parotees;
            border-color: $parotees;
        }

        #biggest-loser & {
            background: $biggest-loser;
            border-color: $biggest-loser;
        }

        #parotees & {
            background: $parotees;
            border-color: $parotees;
        }

        #medifast & {
            background: $medifast;
            border-color: $medifast;
        }

        #tsfl & {
            background: $tsfl;
            border-color: $tsfl;
        }

        #ahalife & {
            background: $ahalife;
            border-color: $ahalife;
        }

        #deepak & {
            background: $deepak;
            border-color: $deepak;
            color: #222;
        }

        #appfigures & {
            background: $appfigures;
            border-color: $appfigures;
        }

        #webby & {
            background: $webby;
            border-color: $webby;
        }

        #arrigoni & {
            background: $arrigoni;
            border-color: $arrigoni;
        }

        #pedini & {
            background: $pedini;
            border-color: $pedini;
        }

        #dave & {
            background: $davidchang;
            border-color: $davidchang;
        }
    }
    
    .animate & {
        opacity: 1;
        transform: translateY(0px);
        -moz-transform: translateY(0px);
        -o-transform: translateY(0px);
        -webkit-transform: translateY(0px);

        button {
            opacity: 1;
            transform: translateY(0px);
            -moz-transform: translateY(0px);
            -o-transform: translateY(0px);
            -webkit-transform: translateY(0px);
        }
    }
}

.portfolio_heading {
	font-size: 72px;
	font-weight: 600;
    transform: translateY(20px);
    -moz-transform: translateY(20px);
    -o-transform: translateY(20px);
    -webkit-transform: translateY(20px);
	opacity: 0;
    text-shadow: 0px 0px 6px rgba(0,0,0,1);
    transition: .2s ease-in-out .3s;
    -o-transition: .2s ease-in-out .3s;
    -moz-transition: .2s ease-in-out .3s;
    -webkit-transition: .2s ease-in-out .3s;
    
    .animate & {
        opacity: 1;
        transform: translateY(0%);
        -moz-transform: translateY(0%);
        -o-transform: translateY(0%);
        -webkit-transform: translateY(0%);
    }
}

.portfolio_subheading {
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	transform: translateY(20px);
    -moz-transform: translateY(20px);
    -o-transform: translateY(20px);
    -webkit-transform: translateY(20px);
	opacity: 0;
    text-shadow: 0px 0px 6px rgba(0,0,0,1);
    transition: .2s ease-in-out .7s;
    -o-transition: .2s ease-in-out .7s;
    -moz-transition: .2s ease-in-out .7s;
    -webkit-transition: .2s ease-in-out .7s;
    
    .animate & {
        opacity: 1;
        transform: translateY(0%);
        -moz-transform: translateY(0%);
        -o-transform: translateY(0%);
        -webkit-transform: translateY(0%);
    }
}

.icon__container {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
    transition: .2s ease-in-out;
    -moz-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    -webkit-transition: .2s ease-in-out;
		
    @media screen and (max-width: 1340px) {
        top: 30vw;
    }
    
    @media screen and (max-width: 1050px) {
        top: 40vw;
    }
		
    @media screen and (max-width: $bp-large) {
        position: relative;
        top: auto;
        transform: none;
        -o-transform: none;
        -moz-transform: none;
        -webkit-transform: none;
    }
		
    @media screen and (max-width: $bp-medium) {
        padding: 0 20px;
    }
  }

.icon__item {
    width: auto;
    margin: 10px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transform: translateY(20px);
    -o-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -webkit-transform: translateY(20px);
    text-shadow: 0px 0px 6px rgba(black,0.6);
    text-align: center;

    &:nth-child(1) {
        transition: .2s ease-in-out .35s;
        -o-transition: .2s ease-in-out .35s;
        -moz-transition: .2s ease-in-out .35s;
        -webkit-transition: .2s ease-in-out .35s;
    }

    &:nth-child(2) {
        transition: .2s ease-in-out .4s;
        -o-transition: .2s ease-in-out .4s;
        -moz-transition: .2s ease-in-out .4s;
        -webkit-transition: .2s ease-in-out .4s;
    }

    &:nth-child(3) {
        transition: .2s ease-in-out .45s;
        -o-transition: .2s ease-in-out .45s;
        -moz-transition: .2s ease-in-out .45s;
        -webkit-transition: .2s ease-in-out .45s;
    }

    &:nth-child(4) {
        transition: .2s ease-in-out .5s;
        -o-transition: .2s ease-in-out .5s;
        -moz-transition: .2s ease-in-out .5s;
        -webkit-transition: .2s ease-in-out .5s;
    }

    &:nth-child(5) {
        transition: .2s ease-in-out .55s;
        -o-transition: .2s ease-in-out .55s;
        -moz-transition: .2s ease-in-out .55s;
        -webkit-transition: .2s ease-in-out .55s;
    }

    &:nth-child(6) {
        transition: .2s ease-in-out .6s;
        -o-transition: .2s ease-in-out .6s;
        -moz-transition: .2s ease-in-out .6s;
        -webkit-transition: .2s ease-in-out .6s;
    }

    &:nth-child(7) {
        transition: .2s ease-in-out .65s;
        -o-transition: .2s ease-in-out .65s;
        -moz-transition: .2s ease-in-out .65s;
        -webkit-transition: .2s ease-in-out .65s;
    }

    .animate & {
        opacity: 1;
        transform: translateY(0);
        -o-transform: translateY(0);
        -moz-transform: translateY(0);
        -webkit-transform: translateY(0);
    }
		
	@media screen and (max-width: 800px) {
		margin: 10px 20px;
	}
		
	@media screen and (max-width: $bp-medium) {
		margin: 8px 10px;
		width: 70px;
	}
		
	@media screen and (max-width: $bp-small) {
		width: 55px;
    }
    
    .icon-graphic-design, .icon-ux, .icon-responsive, .icon-package {
		font-size: 32px;
    }
    .icon-photography {
            font-size: 32px;
    }
    .icon-responsive, .icon-brochure, .icon-video {
            font-size: 32px;
    }
    .icon-code {
            font-size: 32px;
    }
    .icon-flash {
            font-size: 32px;
    }
}

.icon__text {
    font-size: 10px;
    margin: 5px 0;
    letter-spacing: 1px;
    line-height: 1.2;
    font-weight: 400;

    @media screen and (max-width: $bp-medium) {
        transform: scale(0.8);
        -o-transform: scale(0.8);
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
    }
}

.iconcss {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $bp-medium) {
        transform: scale(0.8);
        -o-transform: scale(0.8);
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
    }
}