$bp-xlarge: 1800px;
$bp-large: 1400px;
$bp-desktop: 1100px;
$bp-medium: 800px;
$bp-small: 600px;

$basic-transition: all 0.2s ease-out;

$greyBG: #3d3d3d;
$chillerRed: #BE0C36;
$twobravo: #1b1b1b;
$ahalife: #fb6d61;
$appfigures: #ef683a;
$arrigoni: #cc8729;
$biggest-loser: #015eac;
$calle: #c68511;
$chiller: radial-gradient(at 41% top, #202020, #000000);
$cosgrove: #c11029;
$davidchang: #293838;
$disney: #0599ca;
$deepak: #d9d1cc;
$favela: #b62025;
$jurassic: rgba(21, 42, 57, 1);
$lorax: #ff7d01;
$medifast: #0061b6;
$nbcux: #400095;
$outfront: #a300f9;
$parotees: #00a1de;
$pedini: #222;
$pcc: #c894c4;
$sema: #8faa8b;
$softheon: #0088ce;
$tsfl: #bc6dc7;
$webby: #1b1b1b;
$jurassicBlue: #46b3e3;
$rtr: #464655;

$background: rgb(85, 85, 85);
$secondary: salmon;