.overlay_content_wrapper {
    overflow-y:hidden;

    .overlay_section {
        position: absolute;
        width: 100%;
        top: 0;
        overflow: hidden;
        z-index: 1;

        &.multi-section {
            display: block;
        }
    }
}

.image-grid-3 {
    width: 18%;
    height: auto;
    position: absolute;
}

.section-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    position: relative;

    .image-grid-3 {
        &:first-child {
            top: 0;
            left:20px;
        }
        &:nth-child(2) {
            top: 0;
            right: 22%;
        }
        &:nth-child(3) {
            top: 0;
            right:20px;
        }
    }
}

.overlay_wrapper {
    overflow: hidden;
}

.overlay-shadow {
    position: absolute;
    top: 0;
    left: 0;
    background: black;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: -5;
    
    
    .wireframes & {
        left: 70px;
    }
}

.overlay_section {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background: white;
    padding-left: 70px;
    overflow: hidden;

    &.triple {
        color: $greyBG;
        width: calc(100% - 70px);
        left: 70px;
        background: white;
        padding-left: 0;

        &:before {
            width: 100%;
            height: 100%;
            position: absolute;
            content: '';
            background: white;
        }

        .overlay-shadow {
            height: calc(100% + 20px);
        }

        .text {
            color: $greyBG;
            margin: 0;
        }
    
        .image {
            width: 100%;
            
            img {
                box-shadow: none;
            }

            &.top {
                img {
                    top: 10%;
                    position: absolute;
                    width: 1000px;
                    left: -300px;
                }
            }

            &.bottom {
                img {
                    bottom: 100px;
                    position: absolute;
                    width: 1000px;
                }
            }
        }
    }

    &.wireframes {
        height: 100vh;
        overflow: hidden;
        position: relative;
        align-items: flex-start;
        background: white;
        justify-content: space-around;
        padding-left: 70px;
        padding-top: 60px;
        
        .image {
            position: relative;
            width: 50%;
        }
        .text {
            align-self: center;
            background: #f3f3f3;
        }
    }

    &.competition {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .netflix {
            padding: 20px;
        }

        .image {
            width: 52%;
            margin-right: 8%;
        }
    }

    @media screen and (max-width: $bp-desktop) {
        flex-wrap: wrap;
    }
}

.text_wrapper {
    width: 40%;
    padding: 40px;
    color: white;
    max-width: 600px;
    margin-right: 8vw;

    @media screen and (max-width: $bp-desktop) {
        order: 2;
        width: 100%;
    }
}

.text {
    padding: 20px;
    background: white;
    max-width: 500px;
    width: calc(100% - 40px);
    position: relative;
    border-radius: 5px;
}


h3 {
    font-size: 64px;
    font-weight: 600;
    transform: translateY(10px);
    opacity: 0;
    animation: fadeUp .4s ease-in-out .7s 1 forwards;
    -webkit-animation: fadeUp .4s ease-in-out .7s 1 forwards;
    -moz-animation: fadeUp .4s ease-in-out .7s 1 forwards;
    -o-animation: fadeUp .4s ease-in-out .7s 1 forwards;
    padding-bottom: 30px;
    margin-bottom: 30px;
    position: relative;

    &:after {
        content: '';
        height: 1px;
        width: 50%;
        position: absolute;
        background: $greyBG;
        bottom: 0;
        left: 0;
        min-width: 100px;

        #section0 & {
            background: white;
        }
    }
}

h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    transform: translateY(10px);
    opacity: 0;
    animation: fadeUp .4s ease-in-out .9s 1 forwards;
    -webkit-animation: fadeUp .4s ease-in-out .9s 1 forwards;
    -moz-animation: fadeUp .4s ease-in-out .9s 1 forwards;
    -o-animation: fadeUp .4s ease-in-out .9s 1 forwards;
}

#section0 {
    justify-content: flex-end;

    button {
        transform: translateY(10px);
        opacity: 0;
        animation: fadeUp .4s ease-in-out 1.1s 1 forwards;
        -webkit-animation: fadeUp .4s ease-in-out 1.1s 1 forwards;
        -moz-animation: fadeUp .4s ease-in-out 1.1s 1 forwards;
        -o-animation: fadeUp .4s ease-in-out 1.1s 1 forwards;
    }

    .disney & {
        background: url('../img/disney/background.jpg');
        background-size: cover;
        background-position: center center;
    }

    .chiller &, .cosgrove &, .webby &, .dave & {
        background: $chiller;
    }

    .nbc &, .outfront &, .lorax &, .jurassic &, .softheon &, .pcc &, .rtr &, .parotees &, .biggest-loser &, .medifast &, .ahalife &, .pedini & {
        background: #fafafa;

        .image_wrapper img {
            box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.08);
        }

        .text_wrapper {
            color: $greyBG;

            h3 {

                &:after {
                    background: $greyBG;
                }
            }
        }
    }

    .deepak &, .arrigoni & {
        background: white;

        .image_wrapper img {
            box-shadow: none;
        }

        .text_wrapper {
            color: $greyBG;

            h3 {

                &:after {
                    background: $greyBG;
                }
            }
        }
    }

    .calle & {
        background: #21170a;
    }

    .favela & {
        background: #efefef;

        .image_wrapper img {
            box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.08);
        }

        .text_wrapper {
            color: $greyBG;

            h3 {

                &:after {
                    background: $greyBG;
                }
            }
        }
    }
}

#section3 {
    .chiller & {
        .image {
            position: relative;
            right: -100px;
            text-align: right;

            img {
                width: 90%;
            }
        }
        .image_wrapper {
            position: relative;
            right: -100px;
            text-align: right;
            img {
                box-shadow: none;
            }
        }

        .text_wrapper {
            color: $greyBG;
        }
    }
}

.lorax {
    #section0 {
       .image_wrapper {
            right: -100px;

            video {
                width: 100%;
                height: auto;
                left: 0;
                top: 50%;
                position: absolute;
                align-self: center;
                box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.08);
                -o-box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.08);
                -moz-box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.08);
                -webkit-box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.08);
                transform: translate(20px, -50%);
                -o-transform: translate(20px, -50%);
                -moz-transform: translate(20px, -50%);
                -webkit-transform: translate(20px, -50%);
                animation: slideLeft .4s ease-in-out .7s 1 forwards;
                -o-animation: slideLeft .4s ease-in-out .7s 1 forwards;
                -moz-animation: slideLeft .4s ease-in-out .7s 1 forwards;
                -webkit-animation: slideLeft .4s ease-in-out .7s 1 forwards;
                opacity: 0;
            }
        }
    }
}

.image_wrapper {
    width: 60%; 
    height: 100vh;
    position: relative;

    img {
        width: 100%;
        height: auto;
        left: 0;
        top: 50%;
        position: absolute;
        align-self: center;
        box-shadow: 0 0 30px 10px rgba(black, 0.5);
        -o-box-shadow: 0 0 30px 10px rgba(black, 0.5);
        -moz-box-shadow: 0 0 30px 10px rgba(black, 0.5);
        -webkit-box-shadow: 0 0 30px 10px rgba(black, 0.5);
        transform: translate(20px, -50%);
        -o-transform: translate(20px, -50%);
        -moz-transform: translate(20px, -50%);
        -webkit-transform: translate(20px, -50%);
        animation: slideLeft .4s ease-in-out .7s 1 forwards;
        -o-animation: slideLeft .4s ease-in-out .7s 1 forwards;
        -moz-animation: slideLeft .4s ease-in-out .7s 1 forwards;
        -webkit-animation: slideLeft .4s ease-in-out .7s 1 forwards;
        opacity: 0;
    }

    @media screen and (max-width: $bp-desktop) {
        order: 1;
        width: 100vw;
        height: auto;
        max-height: 800px;

        img {
            box-shadow: none !important;
        }
    }
}

.image-caption {
    font-size: 12px;
    position: relative;
    margin-top: 20px;
    padding-top: 20px;
    font-weight: 600;

    span {
        font-weight: 900;
    }

    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;

        &:after {
            left: auto;
            right: 0;
        }
    }

    &:after {
        content: '';
        height: 1px;
        width: 150px;
        position: absolute;
        background: $greyBG;
        top: 0;
        left: 0;
        min-width: 100px;
    }
}

@keyframes slideRight {
    0% {
        transform: translate(-20px, -50%);
        opacity: 0;
    }
    100% {
        transform: translate(0, -50%);
        opacity: 1;
    }
}

@-moz-keyframes slideRight {
    0% {
        -moz-transform: translate(-20px, -50%);
        opacity: 0;
    }
    100% {
        -moz-transform: translate(0, -50%);
        opacity: 1;
    }
}

@-webkit-keyframes slideRight {
    0% {
        -webkit-transform: translate(-20px, -50%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(0, -50%);
        opacity: 1;
    }
}

@keyframes slideLeft {
    0% {
        transform: translate(20px, -50%);
        opacity: 0;
    }
    100% {
        transform: translate(0, -50%);
        opacity: 1;
    }
}

@-moz-keyframes slideLeft {
    0% {
        -moz-transform: translate(20px, -50%);
        opacity: 0;
    }
    100% {
        -moz-transform: translate(0, -50%);
        opacity: 1;
    }
}

@-webkit-keyframes slideLeft {
    0% {
        -webkit-transform: translate(20px, -50%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(0, -50%);
        opacity: 1;
    }
}

@keyframes fadeUp {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@-moz-keyframes fadeUp {
    0% {
        -moz-transform: translateY(10px);
        opacity: 0;
    }
    100% {
        -moz-transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes fadeUp {
    0% {
        -webkit-transform: translateY(10px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}

.container-40 {
    width: 40%; 
    display: flex; 
    justify-content: center;
    position: relative;
    // z-index: 1;

    video {
        width: 100%;
    }
}

.container-60 {
    width: 60%; 
    position: relative; 

    video {
        width: 100%;
    }

    &.off-right {
        right: -100px;
    }

    &.off-left {
        left: -100px;
    }

    &.in-right {
        right: 100px;
    }

    &.in-left {
        left: 100px;
    }

    &.in-left-40 {
        left: 40px;
    }

    &.in-right-40 {
        right: 40px;
    }
}

.devices-wrapper {
    display: flex;
}

.device-item {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: rgba(black, 0.2);
    align-items: center;
    width: 100px;
    margin: 10px 0;
    
    i {
        font-size: 60px;
        margin-bottom:20px;
    }
}

.get-chiller {
    background: url("../img/chiller/william_rajwan_chiller_get_chiller_mobile.jpg");
    background-position: center center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
}

.competition {
    width: 24%;

    &.fx {
        fill: #efcf2d;
    }
}

.video {
    width: 52%;
    margin-right: 8%;
    position: relative;

    video {
        width: 100%;
        position: relative;
    }

    .play-button {
        cursor: pointer;
        position: absolute;
        top: calc(50% - 32px);
        left: 50%;
        -ms-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        -webkit-transform: translate(-50%, -50%) scale(1);
        z-index: 2;
        opacity: 1;
        transition: .25s ease-out;
        pointer-events: none;

        i {
            font-size: 30px;
            padding: 20px;
            color: white;
            border: 2px solid white;
            border-radius: 50%;
            height: 70px;
            width: 70px;
            text-align: center;
            background: rgba(0, 0, 0, 0.05);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }
    }

    &.active {
        .play-button {
            -o-transform: translate(-50%, -50%) scale(1.5);
            -webkit-transform: translate(-50%, -50%) scale(1.5);
            -ms-transform: translate(-50%, -50%) scale(1.5);
            transform: translate(-50%, -50%) scale(1.5);
            opacity: 0;
        }
    }
}

.owl-carousel {
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.slideshow {
    padding-left: 0;
}

.nbc {
    #section4, #section5, #section6 {
        .image {
            width: 52%;
            margin-right: 8%;
        }
    }
}

.section-inner {
    margin: 0px auto;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    width: 100%;
    padding: 40px;

    video {
        display: block;
        width: 100%;
        height: auto;
    }

    .section-left {
        width: 48%;
        max-width: 1000px;
    }

    .section-right {
        max-width: 600px;
        width: 48%;
        margin-left: 8vw;
    }

    .user-animation {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        position: relative;
      
        @media screen and (max-width: $bp-small) {
          height: 20vw;
        }
      
        .user {
          position: absolute;
          width: 20%;
          display: none;
          right: 25%;
      
          &:first-child {
            transform: scale(1) translate3d(100%, 0, 0);
            display: block;
          }
      
          &:nth-child(2) {
            transform: scale(1) translate3d(calc(0% + -14%), 0, 0);
            display: block;
          }
      
          &:nth-child(3), &:nth-child(4) {
            left: 52%
          }
      
          .animation-begin & {
            &:nth-child(2) {
              animation-name: slide;
              animation-fill-mode: forwards;
              animation-duration: 1.5s;
              animation-timing-function: linear;
              animation-delay: 1s;
            }
            &:nth-child(3) {
              animation-name: growSlide;
              animation-fill-mode: forwards;
              animation-duration: 1.5s;
              animation-timing-function: linear;
              animation-delay: 1s;
              display: block;
              opacity: 0;
            }
      
            &:nth-child(4) {
              animation-name: growSlideLast;
              animation-fill-mode: forwards;
              animation-duration: 1.5s;
              animation-timing-function: linear;
              animation-delay: 1s;
              display: block;
              opacity: 0;
            }
          }
      
          img {
            width: 100%;
            height: auto;
            border-radius: 50%;
          }
        }
      }
      
    .dynamic-item-description {
        font-size: 14px;
        font-weight: 300;
        line-height: 25px;
        display: none;
    }
      
      .dynamic-item-title {
        color: #4386fc;
        cursor: pointer;
        font-family: 'robotolight', sans-serif;
        transition: .3s ease-out;
       
        .nbc & {
            color: $nbcux;

            &:hover {
                color: $nbcux;
            }
        }

        .outfront & {
            color: $outfront;

            &:hover {
                color: $outfront;
            }
        }

        &:hover {
          color: #2263d6;
        }
      }
      
      /*
      .section-left, .section-right {
        position: relative;
        min-height: 400px;
        @media screen and (max-width: @bp-small) {
          min-height: 0;
        }
      }
      */
      
      .dynamic-content {
        position: relative;
        padding-top: 56%;
      
        @media screen and (max-width: $bp-small) {
          padding-top: 70%;
        }
      
      }
      
      .dynamic-item {
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transition: opacity 0.5s;
        transform: translateY(-50%);
        &.active {
          opacity: 1;
        }
      }
      
      .dynamic-trigger {
        margin-bottom: 15px;
        cursor: pointer;
        border-bottom: 1px solid #eee;
        padding-bottom: 15px;

        &:last-child {
            border-bottom: none;
        }
      
        &.active {
            p {
            display: block;
            
            }
            .dynamic-item-description {
                display: block;
            }

            .dynamic-item-title {
                color: #444;
                font-size: 21px;
                margin-bottom: 10px;
            }
        }
      
        p {
            display: none;
            font-size: 14px;
            font-weight: 300;
            line-height: 25px;
        }

        .quote {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.3);
            text-transform: uppercase;
            margin-bottom: 20px;
        }
      }
}

@keyframes slide {
    0% {
      transform: scale(1) translate3d(calc(0% + -14%), 0, 0);
    }
    22% {
      transform: scale(1) translate3d(calc(-100% + -27%), 0, 0);
    }
    50% {
      transform: scale(1) translate3d(calc(-100% + -27%), 0, 0);
    }
    78%, 100% {
      transform: scale(1) translate3d(calc(-200% + -40%), 0, 0);
    }
  }

  @keyframes growSlide {
    0% {
      transform: scale(0) translate3d(0, 0, 0);
      opacity: 1;
    }
    22% {
      transform: scale(0) translate3d(0, 0, 0);
      opacity: 1;
    }
    30% {
      transform: scale(1.1) translate3d(0, 0, 0);
      opacity: 1;
    }
    34% {
      transform: scale(1) translate3d(0, 0, 0);
      opacity: 1;
    }
    50% {
      transform: scale(1) translate3d(0, 0, 0);
      opacity: 1;
    }
    78%, 100% {
      transform: scale(1) translate3d(calc(-100% + -14%), 0, 0);
      opacity: 1;
    }
  }
  
  @keyframes growSlideLast {
    0% {
      transform: scale(0) translate3d(0, 0, 0);
      opacity: 1;
    }
    78% {
      transform: scale(0) translate3d(0, 0, 0);
      opacity: 1;
    }
    86% {
      transform: scale(1.1) translate3d(0, 0, 0);
      opacity: 1;
    }
    88%, 100% {
      transform: scale(1) translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  #ime-features {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 70px;
    opacity: 0;
  }

  .outfront {
    .image-caption {
        width: 100%;
    }

    .screen-wrapper {
        display: flex;
        width: 100%;
        height: 100%;

        .play-button {
            cursor: pointer;
            position: absolute;
            top: calc(50% - 32px);
            left: 50%;
            -ms-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
            -webkit-transform: translate(-50%, -50%) scale(1);
            z-index: 2;
            opacity: 1;
            transition: .25s ease-out;
            pointer-events: none;
    
            i {
                font-size: 30px;
                padding: 20px;
                color: white;
                border: 2px solid white;
                border-radius: 50%;
                height: 70px;
                width: 70px;
                text-align: center;
                background: rgba(0, 0, 0, 0.05);
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            }
        }
    }

    .screen-item-wrapper {
        padding: 0 80px;
        @include center;
        position: absolute;
        width: 100%;
        opacity: 1;
        transition: .4s ease-in-out;

        &.active {
            .play-button {
                -o-transform: translate(-50%, -50%) scale(1.5);
                -webkit-transform: translate(-50%, -50%) scale(1.5);
                -ms-transform: translate(-50%, -50%) scale(1.5);
                transform: translate(-50%, -50%) scale(1.5);
                opacity: 0;
            }
        }
    }

    .screen {
        position: relative;

        &:nth-child(2) {
            transform: translateX(calc(0% - 20px));
            -webkit-transform: translateX(calc(0% - 20px));
            -ms-transform: translateX(calc(0% - 20px));
            -o-transform: translateX(calc(0% - 20px));
        }

        &:nth-child(4) {
            transform: translateX(calc(0% + 20px));
            -webkit-transform: translateX(calc(0% + 20px));
            -ms-transform: translateX(calc(0% + 20px));
            -o-transform: translateX(calc(0% + 20px));
        }

        video {
            border-radius: 10px;
            border: 6px solid #242424;
        }
    }
    
      #section3 {

        .screen-item-wrapper {
            opacity: 0;

            &:first-child {

                .image-caption {
                    opacity: 0;
                    transition: .4s ease-in-out .4s;
                    -webkit-transition: .4s ease-in-out .4s;
                    -ms-transition: .4s ease-in-out .4s;
                    -o-transition: .4s ease-in-out .4s;
                }
                
                .screen {
                    position: relative;
                    opacity: 0;
                    transition: .4s ease-in-out;
                    -webkit-transition: .4s ease-in-out;
                    -ms-transition: .4s ease-in-out;
                    -o-transition: .4s ease-in-out;
        
                    video {
                        border-radius: 10px;
                        border: 6px solid #242424;
                    }
        
                    &:after {
                        content: '';
                        background: #242424;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        border-radius: 10px;
                        transition: .4s ease-in-out .4s;
                        -webkit-transition: .4s ease-in-out .4s;
                        -ms-transition: .4s ease-in-out .4s;
                        -o-transition: .4s ease-in-out .4s;
                    }
        
                    &:nth-child(1) {
                        transform: translateX(100%);
                        -webkit-transform: translateX(100%);
                        -ms-transform: translateX(100%);
                        -o-transform: translateX(100%);
                        transition: opacity .4s ease-in-out, transform .4s ease-in-out .4s;
                        -webkit-transition: .4s ease-in-out, transform .4s ease-in-out .4s;
                        -ms-transition: .4s ease-in-out, transform .4s ease-in-out .4s;
                        -o-transition: .4s ease-in-out, transform .4s ease-in-out .4s;
                    }

                    &:nth-child(2) {
                        transform: none;
                        -webkit-transform: none;
                        -ms-transform: none;
                        -o-transform: none;
                    }
        
                    &:nth-child(3) {
                        transform: translateX(-100%);
                        -webkit-transform: translateX(-100%);
                        -ms-transform: translateX(-100%);
                        -o-transform: translateX(-100%);
                        transition-delay: .8s;
                        -webkit-transition-delay: .8s;
                        -o-transition-delay: .8s;
                        -ms-transition-delay: .8s;
                        transition: opacity .4s ease-in-out, transform .4s ease-in-out .4s;
                        -webkit-transition: .4s ease-in-out, transform .4s ease-in-out .4s;
                        -ms-transition: .4s ease-in-out, transform .4s ease-in-out .4s;
                        -o-transition: .4s ease-in-out, transform .4s ease-in-out .4s;
                    }
                }
            }
        }

        &.animate {
            #section3 {
                        
                .screen { 
                    &:nth-child(3) {
                        transform: translateX(calc(0% + 20px));
                        -webkit-transform: translateX(calc(0% + 20px));
                        -ms-transform: translateX(calc(0% + 20px));
                        -o-transform: translateX(calc(0% + 20px));
                    }
                }
            }
            .screen-item-wrapper {
                &:first-child {
                    opacity: 1;
                }
                &:first-child {
                    .image-caption {
                        opacity: 1;
                    }

                    .screen {
                        opacity: 1;
        
                        &:after {
                            content: '';
                            background: #242424;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            border-radius: 10px;
                            opacity: 0;
                        }
        
                        &:nth-child(1) {
                            transform: translateX(calc(0% - 20px));
                            -webkit-transform: translateX(calc(0% - 20px));
                            -ms-transform: translateX(calc(0% - 20px));
                            -o-transform: translateX(calc(0% - 20px));
                        }
        
                        &:nth-child(3) {
                            transform: translateX(calc(0% + 20px));
                            -webkit-transform: translateX(calc(0% + 20px));
                            -ms-transform: translateX(calc(0% + 20px));
                            -o-transform: translateX(calc(0% + 20px));
                        }
                    }
                }
            }
        }
    }
}

#user-universe {
    height: 70vh;
    width: auto;
}

.outfront {
    #section9 {
        .container-60 {
            overflow: hidden;
        }
    }

    #section10 {
        display: flex;

        .text-container {
            display: flex;
            color: white;
            align-items: center;
            justify-content: center;
            z-index: 2;
            flex-direction: column;
            position: absolute;
            @include center;
            font-size: 32px;

            i {
                font-size: 100px;
                margin-bottom: 40px;
                height: 100%;
            }
        }

        video {
            object-fit: cover;
            -webkit-filter: blur(10px);
            -ms-filter: blur(10px);
            -o-filter: blur(10px);
            filter: blur(10px);
            width: calc(100% + 70px);
            height: 100%;
            position: relative;
            left: -70px;
        }
        &:after {
            content: '';
            width: calc(100% + 70px);
            height: 100%;
            position: absolute;
            background: rgba(black, 0.8);
            left: -70px
        }
    }
}

.cosgrove {
    .wireframes {
        background: #f3f3f3;
    }
}

.softheon {
    #section7 {
        video {
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            -moz-transform: translate(-50%,-50%);
            -o-transform: translate(-50%,-50%);
            -webkit-transform: translate(-50%,-50%);
        }
    }
}

.parotees {
    #section2 {
        background: url('../img/parotees/william_rajwan_parotees_clothing_sketches.jpg');
        background-size: cover;
        background-position: center center;

    }
}

.medifast {
    .image_wrapper {
        img {
            max-height: 800px;
            width: auto;
            box-shadow: none !important;
            left: auto;
            right:10vw;
        }
    }

    .multi-section {
        .container-40 {
            left: -12%;
        }
    }

    .image-grid-3 {
        &:nth-child(4) {
            left: 1%;
        }

        &:nth-child(2), &:nth-child(3) {
            top: auto;
        }

        &:nth-child(2) {
            right: 26%;
        }

        &:nth-child(3) {
            right: 4%;
        }
    }
}

.deepak {
    .overlay_section.triple .image.bottom {
        img {
            width: 800px;
        }
    }
}

.arrigoni {
    #section3 {
        background: url('../img/arrigoni/william_rajwan_arrigoni_woods_postcard.jpg');
        background-size: cover;
        background-position: center center;

    }
}