.details_button_wrapper {
	display: flex;
	align-items: center;
	// margin-top: 20px;
	// transform: translateY(100%);
	// opacity: 0;
	cursor: pointer;
}

.details_button {
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 2px;
	transition: .2s ease-in-out;
	text-shadow: 0px 0px 6px rgba(0,0,0,1);

	.hover & {
		transform: translateX(-40px);
	}
}

.plus_wrapper {
	background: $chillerRed;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	margin-right: 10px;
	transition: .2s ease-in-out;
    transform: scale(0.8);
    
    #lorax & {
        background: $lorax;
    }
	
	.plus {
		position: relative;
		background: white;
		width: 15px;
		height: 2px;
		top: 13px;
		left: 7px;
		transition: .2s ease-in-out;

		&:after, &:before {
			position: absolute;
			content: '';
			background: white;
			width: 15px;
			height: 2px;
			transform: rotate(90deg);
			transition: .2s ease-in-out;
		}

		.hover & {
			// transform: translateX(100px);

			&:before, &:after {
				width: 8px;
			}

			&:before {
				transform: rotate(60deg) translate(2px, -9.8px);
			}

			&:after {
				transform: rotate(120deg) translate(-2px, -9.8px);
			}
		}
	}

	.hover & {
		transform: scale(0.8) translateX(107px);
	}
}

button {
    color: white;
    padding: 16px 28px;
    border-radius: 1px;
    font-size: 14px;
    outline: none;
    font-weight: 400;
    letter-spacing: 1;
	cursor: pointer;
	text-transform: capitalize;
	border: 2px solid $greyBG;
	margin-top: 20px;
	position: relative;
	z-index: 1;

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: black;
		opacity: 0;
		transition: .3s ease-in-out;
		position: absolute;
		z-index: -1;
	}

	&:hover {
		&:before {
			opacity: 0.2;
		}
	}

    .lorax & {
		border-color: $lorax;
		background: $lorax;
	}

	.chiller & {
		background: $chillerRed;
		border-color: $chillerRed;
	}

	.nbc & {
		background: $nbcux;
		border-color: $nbcux;
	}

	.outfront & {
		background: $outfront;
		border-color: $outfront;
	}

	.disney & {
		background: $disney;
		border-color: $disney;
	}

	.jurassic & {
		background: $jurassicBlue;
		border-color: $jurassicBlue;
	}

	.cosgrove & {
		background: $cosgrove;
		border-color: $cosgrove;
	}

	.softheon & {
		background: $softheon;
		border-color: $softheon;
	}

	.calle & {
		background: $calle;
		border-color: $calle;
	}

	.favela & {
		background: $favela;
		border-color: $favela;
	}

	.pcc & {
		background: $pcc;
		border-color: $pcc;
	}

	.parotees & {
		background: $parotees;
		border-color: $parotees;
	}

	.biggest-loser & {
		background: $biggest-loser;
		border-color: $biggest-loser;
	}

	.parotees & {
		background: $parotees;
		border-color: $parotees;
	}

	.medifast & {
		background: $medifast;
		border-color: $medifast;
	}

	.tsfl & {
		background: $tsfl;
		border-color: $tsfl;
	}

	.ahalife & {
		background: $ahalife;
		border-color: $ahalife;
	}

	.deepak & {
		background: $deepak;
		border-color: $deepak;
		color: #222;
	}

	.appfigures & {
		background: $appfigures;
		border-color: $appfigures;
	}

	.webby & {
		background: $webby;
		border-color: $webby;
	}

	.arrigoni & {
		background: $arrigoni;
		border-color: $arrigoni;
	}

	.pedini & {
		background: $pedini;
		border-color: $pedini;
	}

	.rtr & {
		background: $rtr;
		border-color: $rtr;
	}

	.dave & {
		background: $davidchang;
		border-color: $davidchang;
	}

}