@mixin trigger {
  color: rgba(white, 0.8);
  cursor: pointer;
  transition: $basic-transition;
}

@mixin trigger-hover {
  color: white;
}

@mixin center {
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
}

@mixin shadow($color, $steps, $glow) {
  $all: ();
  $all: append($all, -1px 1px 0 rgba($color,.1), comma);
  $all: append($all, 1px -1px 0 rgba($glow,.1), comma);
  $all: append($all, 1px -1px 25px $glow, comma);
  @for $i from 1 through $steps {
    $all: append($all, append($i*-1px $i*1.9px $i*1px, rgba($color,2/$i)));
  }
  text-shadow: $all;
}

@mixin shadow-alternate($color, $steps, $glow) {
  $all: ();
  $all: append($all, -1px -1px 0 rgba($color,.1), comma);
  $all: append($all, 1px 1px 0 rgba($glow,.1), comma);
  $all: append($all, 1px -1px 25px $glow, comma);
  @for $i from 1 through $steps {
    $all: append($all, append($i*-1px $i*-1.9px $i*1px, rgba($color,2/$i)));
  }
  text-shadow: $all;
}