.progress {
    position: relative;
    left: 0px;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
		background-color: white; 
		overflow: hidden;
}

 .bar { 
    // background: linear-gradient(-45deg, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #7ea8f1, #57b7fc, #00c4ff, #00d8fd, #00e8e3, #00f4b2, #5ffb73);
		// background-size: 400% 400%;
		// -webkit-animation: Gradient 15s ease infinite;
		// -moz-animation: Gradient 15s ease infinite;
		// animation: Gradient 15s ease infinite;
		background: radial-gradient(ellipse at center, #444444 0%,#222222 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    width:0%; 
    height:10px; 
    @include center;  
  	position: absolute;
  	border-top: 2px solid white;
		border-bottom: 2px solid white;
		overflow: hidden;


  .extend & {
    height: 100%;
	transition: .2s ease-out;
	-moz-transition: .2s ease-out;
	-o-transition: .2s ease-out;
	-webkit-transition: .2s ease-out;
  }

  .animate & {
    width: 100%;
		height: 100%;
		// animation: gradientToSolid .8s ease 1 forwards;
		border: none;
    }
}
.percent { 
	position: absolute; 
	@include center;
	color: rgba(black, 0.05);
	font-size: 200px;
	letter-spacing: -4px;
	z-index: -1;

	.extend & {
		opacity: 0;
		transition: .2s ease-out;
		-moz-transition: .2s ease-out;
		-o-transition: .2s ease-out;
		-webkit-transition: .2s ease-out;
	}

	.animate & {
		display: none;
	}
}

@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes gradientToSolid {
	0% {
		background: linear-gradient(-45deg, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #7ea8f1, #57b7fc, #00c4ff, #00d8fd, #00e8e3, #00f4b2, #5ffb73);
	}
	100% {
		background: radial-gradient(ellipse at center, #444444 0%,#222222 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	}
}