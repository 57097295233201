/* Begin Ad 1 */
#ad-1 {
	width: 728px;
	height: 90px;
	margin: 40px auto 0;
	background: #ffffff;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
	position: relative;
	box-shadow: 1px 1px 4px rgba(0,0,0,0.4);
}
#ad-1 #content {
	width: 325px;
	float: right;
	margin: 40px;
	text-align: center;
	z-index: 4;
	position: relative;
	overflow: visible;
}
	
#ad-1 .barclay-text img {
	height: auto;
	position: absolute;
	left: -165px;
	top: -590px;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	-webkit-animation: barclaytext-animation 1.9s 1 linear;
	-moz-animation: barclaytext-animation 1.9s 1 linear;
}

#ad-1 .apply-button {
	width: 120px;
	height: 25px;
	background: url('../img/cosgrove/bcus/apply-button.png');
	position: absolute;
	bottom: 2px;
	right: 2px;
	cursor: pointer;
	z-index: 9999;
}

#ad-1 .arrival-text {
	width: 282px;
	height: 42px;
	background: url('../img/cosgrove/bcus/arrival-text.png');
	position: absolute;
	bottom: -5px;
	right: 106px;
	z-index: 9999;
}

#ad-1 .blue-bg {
	background: url('../img/cosgrove/bcus/blue-bg.jpg') repeat-x;
	width: 728px;
	height: 90px;
	-webkit-animation: bluebg 16s 1 linear;
	-moz-animation: bluebg 16s 1 linear;
}

#ad-1 .clouds {
	background: url('../img/cosgrove/bcus/clouds.png');
	width: 1412px;
	height: 165px;
	position: absolute;
	top: 40px;
	left: -630px;
	z-index: 2;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	-webkit-animation: clouds 16s 1 linear;
	-moz-animation: clouds 16s 1 linear;
}

#ad-1 .terms {
	font-size: 8px;
	font-family: Arial, Helvetica, Verdana;
	color: #02639a;
	position: absolute;
	left: 3px;
	bottom: 3px;
	z-index: 10;
	-webkit-animation: terms 16s 1 linear;
	-moz-animation: terms 16s 1 linear;
}

#ad-1 .fortyk-text {
	background-image: url('../img/cosgrove/bcus/40k-text.png');
	width: 290px;
	height: 47px;
	position: absolute;
	z-index: 3;
	top: 10px;
	right: -290px;
	-webkit-animation: fortyk-txt 16s 1 linear;
	-moz-animation: fortyk-txt 16s 1 linear;
}

#ad-1 .two-text {
	background-image: url('../img/cosgrove/bcus/2x-text.png');
	width: 226px;
	height: 42px;
	position: absolute;
	z-index: 3;
	top: 10px;
	right: -226px;
	-webkit-animation: two-txt 16s 1 linear;
	-moz-animation: two-txt 16s 1 linear;
}

#ad-1 .quote-text {
	background-image: url('../img/cosgrove/bcus/quote.png');
	width: 395px;
	height: 45px;
	position: absolute;
	z-index: 3;
	top: 10px;
	right: -395px;
	-webkit-animation: quote-txt 16s 1 linear;
	-moz-animation: quote-txt 16s 1 linear;
}

#ad-1 .card {
	background-image: url('../img/cosgrove/bcus/card-art.png');
	width: 92px;
	height: 58px;
	position: absolute;
	z-index: 5;
	top: 7px;
	left: -92px;
	-webkit-animation: first-card 16s 1 linear;
	-moz-animation: first-card 16s 1 linear;
}

#ad-1 .clouds-2 {
	background-image: url('../img/cosgrove/bcus/cloud-overlay.png');
	width: 1034px;
	height: 290px;
	position: absolute;
	z-index: 6;
	top: -290px;
	left: -140px;
	-webkit-animation: cloud-overlay 16s 1 linear;
	-moz-animation: cloud-overlay 16s 1 linear;
}

#ad-1 .card-small {
	background-image: url('../img/cosgrove/bcus/card-art-small.png');
	width: 84px;
	height: 53px;
	position: absolute;
	z-index: 5;
	top: 7px;
	right: 18px;
	-webkit-animation: second-card 16s 1 linear;
	-moz-animation: second-card 16s 1 linear;
}

#ad-1 .palm-tree {
	background-image: url('../img/cosgrove/bcus/palm-tree.png');
	width: 74px;
	height: 90px;
	position: absolute;
	z-index: 6;
	bottom: 0px;
	left: -74px;
	-webkit-animation: palm-tree 16s 1 linear;
	-moz-animation: palm-tree 16s 1 linear;
}

#ad-1 .beach {
	background-image: url('../img/cosgrove/bcus/beach.png');
	width: 728px;
	height: 19px;
	position: absolute;
	z-index: 5;
	bottom: 0px;
	left: 0px;
	-webkit-animation: beach 16s 1 linear;
	-moz-animation: beach 16s 1 linear;
}

#ad-1 .award {
	background-image: url('../img/cosgrove/bcus/award.png');
	width: 83px;
	height: 59px;
	position: absolute;
	z-index: 5;
	top: 7px;
	right: 110px;
	-webkit-animation: award 16s 1 linear;
	-moz-animation: award 16s 1 linear;
}

#ad-1 .fortyk-text-end {
	background-image: url('../img/cosgrove/bcus/40k-text.png');
	width: 290px;
	height: 47px;
	position: absolute;
	z-index: 5;
	top: 10px;
	left: 241px;
	-webkit-animation: fortyk-txt-end 16s 1 linear;
	-moz-animation: fortyk-txt-end 16s 1 linear;
}

#ad-1 .two-text-end {
	background-image: url('../img/cosgrove/bcus/2x-text.png');
	width: 226px;
	height: 42px;
	position: absolute;
	z-index: 5;
	top: 10px;
	left: 11px;
	-webkit-animation: two-txt-end 16s 1 linear;
	-moz-animation: two-txt-end 16s 1 linear;
}

/* WebKit Animations */

@-webkit-keyframes barclaytext-animation {
	0%   {
		opacity: 0.5; 
		width: 212px;
		top: 30px;
		left: 250px;
	}
	10% {
		opacity: 1;
		width: 500px;
		top: 25px;
		left: 100px;
	}
	80% {
		opacity: 1;
		width: 559px;
		top: 25px;
		left: 80px;
	}
	100% {
		opacity: 1;
		width: 13974px;
		left: -165px;
		top: -590px;
	}
}

@-webkit-keyframes bluebg {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes clouds {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
		top: 40px;
		left: -450px;
	}
	11% {
		opacity: 1;
	}
	88% {
		opacity: 1;
		top: 40px;
		left: -630px;
	}
	89% {
		opacity: 1;
		top: 40px;
		left: -630px;
	}
	90% {
		opacity: 0;
		top: 40px;
		left: -630px;
	}
	100% {
		opacity: 0;
		top: 95px;
		left: -630px;
	}
}

@-webkit-keyframes terms {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes two-txt {
	0% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	12% {
		opacity: 1;
		top: 10px;
		right: -226px;
	}
	14% {
		opacity: 1;
		top: 10px;
		right: 260px;
	}
	36% {
		opacity: 1;
		top: 10px;
		right: 260px;
	}
	41% {
		opacity: 1;
		top: 10;
		right: 730px;
	}
	100% {
		opacity: 1;
		top: 10px;
		right: 730px;
	}
}

@-webkit-keyframes fortyk-txt {
	0% {
		opacity: 0;
	}
	41% {
		opacity: 1;
	}
	42% {
		opacity: 1;
		top: 10px;
		right: -290px;
	}
	44% {
		opacity: 1;
		top: 10px;
		right: 85px;
	}
	60% {
		opacity: 1;
		top: 10px;
		right: 85px;
	}
	65% {
		opacity: 1;
		top: 10;
		right: 730px;
	}
	100% {
		opacity: 1;
		top: 10px;
		right: 730px;
	}
}

@-webkit-keyframes quote-txt {
	0% {
		opacity: 0;
	}
	65% {
		opacity: 1;
	}
	66% {
		opacity: 1;
		top: 10px;
		right: -395px;
	}
	71% {
		opacity: 1;
		top: 10px;
		right: 295px;
	}
	88% {
		opacity: 1;
		top: 10px;
		right: 295px;
	}
	89% {
		opacity: 1;
		top:-45px;
		right: 295px;
	}
	100% {
		opacity: 1;
		top:-45px;
		right: 295px;
	}
}

@-webkit-keyframes first-card {
	0% {
		opacity: 0;
	}
	15% {
		opacity: 1;
	}
	16% {
		opacity: 1;
		top: 7px;
		left: -92px;
	}
	78% {
		opacity: 1;
		top: 7px;
		left: 480px;
	}
	88% {
		opacity: 1;
		top: 7px;
		left: 480px;
	}
	89% {
		opacity: 1;
		top: -58px;
		left: 480px;
	}
	100% {
		opacity: 1;
		top: -58px;
		left: 480px;
	}
}

@-webkit-keyframes cloud-overlay {
	0% {
		opacity: 0;
		top: 30px;
		left: -140px;
	}
	88% {
		opacity: 1;
		top: 30px;
		left: -140px;
	}
	90% {
		opacity: 1;
		top: -290px;
		left: -140px;
	}
	100% {
		opacity: 1;
		top: -290px;
		left: -140px;
	}
}

@-webkit-keyframes beach {
	0% {
		opacity: 0;
	}
	89% {
		opacity: 0;
		bottom: -60px;
	}
	91% {
		opacity: 1;
		bottom: 0px;
	}
	100% {
		opacity: 1;
		bottom: 0px;
	}
}

@-webkit-keyframes palm-tree {
	0% {
		opacity: 0;
	}
	89% {
		opacity: 0;
		bottom: -60px;
		left: 0;
	}
	91% {
		opacity: 1;
		bottom: 0px;
		left: 0;
	}
	95% {
		opacity: 1;
		bottom: 0px;
		left: 0;
	}	
	99% {
		opacity: 1;
		bottom: 0px;
		left: -74px;
	}
	100% {
		opacity: 1;
		bottom: 0px;
		left: -74px;
	}	
}

@-webkit-keyframes second-card {
	0% {
		opacity: 0;
	}
	89% {
		opacity: 0;
		top: 67px;
		right: 30px;
	}
	91% {
		opacity: 1;
		top: 7px;
		right: 30px;
	}
	95% {
		opacity: 1;
		top: 7px;
		right: 30px;
	}
	99% {
		opacity: 1;
		top: 7px;
		right: 18px;
	}
	100% {
		opacity: 1;
		top: 7px;
		right: 18px;
	}
}

@-webkit-keyframes two-txt-end {
	0% {
		opacity: 0;
	}

	91% {
		opacity: 0;
		top: 70px;
		left: 75px;
	}
	92% {
		opacity: 1;
		top: 10px;
		left: 75px;
	}
	92% {
		opacity: 1;
		top: 10px;
		left: 75px;
	}
	95% {
		opacity: 1;
		top: 10px;
		left: 75px;
	}
	99% {
		opacity: 1;
		top: 10px;
		left: 11px;
	}
	100% {
		opacity: 1;
		top: 10px;
		left: 11px;
	}
}

@-webkit-keyframes fortyk-txt-end {
	0% {
		opacity: 0;
	}

	91% {
		opacity: 0;
		top: 70px;
		left: 310px;
	}
	92% {
		opacity: 1;
		top: 10px;
		left: 310px;
	}
	95% {
		opacity: 1;
		top: 10px;
		left: 310px;
	}
	99% {
		opacity: 1;
		top: 10px;
		left: 241px;
	}
	100% {
		opacity: 1;
		top: 10px;
		left: 241px;
	}
}

@-webkit-keyframes award {
	0% {
		opacity: 0;
		right: -83px;
	}
	96% {
		opacity: 1;
		right: -83px;
	}
	99% {
		opacity: 1;
		right: 110px;
	}
	100% {
		opacity: 1;
		right: 110px;
	}
}

/* Mozilla Animations */

@-moz-keyframes barclaytext-animation {
	0%   {
		opacity: 0.5; 
		width: 212px;
		top: 30px;
		left: 250px;
	}
	10% {
		opacity: 1;
		width: 500px;
		top: 25px;
		left: 100px;
	}
	80% {
		opacity: 1;
		width: 559px;
		top: 25px;
		left: 80px;
	}
	100% {
		opacity: 1;
		width: 13974px;
		left: -165px;
		top: -590px;
	}
}

@-moz-keyframes bluebg {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes clouds {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
		top: 40px;
		left: -450px;
	}
	11% {
		opacity: 1;
	}
	88% {
		opacity: 1;
		top: 40px;
		left: -630px;
	}
	89% {
		opacity: 1;
		top: 40px;
		left: -630px;
	}
	90% {
		opacity: 0;
		top: 40px;
		left: -630px;
	}
	100% {
		opacity: 0;
		top: 95px;
		left: -630px;
	}
}

@-moz-keyframes terms {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes two-txt {
	0% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	12% {
		opacity: 1;
		top: 10px;
		right: -226px;
	}
	14% {
		opacity: 1;
		top: 10px;
		right: 260px;
	}
	36% {
		opacity: 1;
		top: 10px;
		right: 260px;
	}
	41% {
		opacity: 1;
		top: 10;
		right: 730px;
	}
	100% {
		opacity: 1;
		top: 10px;
		right: 730px;
	}
}

@-moz-keyframes fortyk-txt {
	0% {
		opacity: 0;
	}
	41% {
		opacity: 1;
	}
	42% {
		opacity: 1;
		top: 10px;
		right: -290px;
	}
	44% {
		opacity: 1;
		top: 10px;
		right: 85px;
	}
	60% {
		opacity: 1;
		top: 10px;
		right: 85px;
	}
	65% {
		opacity: 1;
		top: 10;
		right: 730px;
	}
	100% {
		opacity: 1;
		top: 10px;
		right: 730px;
	}
}

@-moz-keyframes quote-txt {
	0% {
		opacity: 0;
	}
	65% {
		opacity: 1;
	}
	66% {
		opacity: 1;
		top: 10px;
		right: -395px;
	}
	71% {
		opacity: 1;
		top: 10px;
		right: 295px;
	}
	88% {
		opacity: 1;
		top: 10px;
		right: 295px;
	}
	89% {
		opacity: 1;
		top:-45px;
		right: 295px;
	}
	100% {
		opacity: 1;
		top:-45px;
		right: 295px;
	}
}

@-moz-keyframes first-card {
	0% {
		opacity: 0;
	}
	15% {
		opacity: 1;
	}
	16% {
		opacity: 1;
		top: 7px;
		left: -92px;
	}
	78% {
		opacity: 1;
		top: 7px;
		left: 480px;
	}
	88% {
		opacity: 1;
		top: 7px;
		left: 480px;
	}
	89% {
		opacity: 1;
		top: -58px;
		left: 480px;
	}
	100% {
		opacity: 1;
		top: -58px;
		left: 480px;
	}
}

@-moz-keyframes cloud-overlay {
	0% {
		opacity: 0;
		top: 30px;
		left: -140px;
	}
	88% {
		opacity: 1;
		top: 30px;
		left: -140px;
	}
	90% {
		opacity: 1;
		top: -290px;
		left: -140px;
	}
	100% {
		opacity: 1;
		top: -290px;
		left: -140px;
	}
}

@-moz-keyframes beach {
	0% {
		opacity: 0;
	}
	89% {
		opacity: 0;
		bottom: -60px;
	}
	91% {
		opacity: 1;
		bottom: 0px;
	}
	100% {
		opacity: 1;
		bottom: 0px;
	}
}

@-moz-keyframes palm-tree {
	0% {
		opacity: 0;
	}
	89% {
		opacity: 0;
		bottom: -60px;
		left: 0;
	}
	91% {
		opacity: 1;
		bottom: 0px;
		left: 0;
	}
	95% {
		opacity: 1;
		bottom: 0px;
		left: 0;
	}	
	99% {
		opacity: 1;
		bottom: 0px;
		left: -74px;
	}
	100% {
		opacity: 1;
		bottom: 0px;
		left: -74px;
	}	
}

@-moz-keyframes second-card {
	0% {
		opacity: 0;
	}
	89% {
		opacity: 0;
		top: 67px;
		right: 30px;
	}
	91% {
		opacity: 1;
		top: 7px;
		right: 30px;
	}
	95% {
		opacity: 1;
		top: 7px;
		right: 30px;
	}
	99% {
		opacity: 1;
		top: 7px;
		right: 18px;
	}
	100% {
		opacity: 1;
		top: 7px;
		right: 18px;
	}
}

@-moz-keyframes two-txt-end {
	0% {
		opacity: 0;
	}

	91% {
		opacity: 0;
		top: 70px;
		left: 75px;
	}
	92% {
		opacity: 1;
		top: 10px;
		left: 75px;
	}
	92% {
		opacity: 1;
		top: 10px;
		left: 75px;
	}
	95% {
		opacity: 1;
		top: 10px;
		left: 75px;
	}
	99% {
		opacity: 1;
		top: 10px;
		left: 11px;
	}
	100% {
		opacity: 1;
		top: 10px;
		left: 11px;
	}
}

@-moz-keyframes fortyk-txt-end {
	0% {
		opacity: 0;
	}

	91% {
		opacity: 0;
		top: 70px;
		left: 310px;
	}
	92% {
		opacity: 1;
		top: 10px;
		left: 310px;
	}
	95% {
		opacity: 1;
		top: 10px;
		left: 310px;
	}
	99% {
		opacity: 1;
		top: 10px;
		left: 241px;
	}
	100% {
		opacity: 1;
		top: 10px;
		left: 241px;
	}
}

@-moz-keyframes award {
	0% {
		opacity: 0;
		right: -83px;
	}
	96% {
		opacity: 1;
		right: -83px;
	}
	99% {
		opacity: 1;
		right: 110px;
	}
	100% {
		opacity: 1;
		right: 110px;
	}
}

/* MS Animations */

@-ms-keyframes barclaytext-animation {
	0%   {
		opacity: 0.5; 
		width: 212px;
		top: 30px;
		left: 250px;
	}
	10% {
		opacity: 1;
		width: 500px;
		top: 25px;
		left: 100px;
	}
	80% {
		opacity: 1;
		width: 559px;
		top: 25px;
		left: 80px;
	}
	100% {
		opacity: 1;
		width: 13974px;
		left: -165px;
		top: -590px;
	}
}

@-ms-keyframes bluebg {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@-ms-keyframes clouds {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
		top: 40px;
		left: -450px;
	}
	11% {
		opacity: 1;
	}
	88% {
		opacity: 1;
		top: 40px;
		left: -630px;
	}
	89% {
		opacity: 1;
		top: 40px;
		left: -630px;
	}
	90% {
		opacity: 0;
		top: 40px;
		left: -630px;
	}
	100% {
		opacity: 0;
		top: 95px;
		left: -630px;
	}
}

@-ms-keyframes terms {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@-ms-keyframes two-txt {
	0% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	12% {
		opacity: 1;
		top: 10px;
		right: -226px;
	}
	14% {
		opacity: 1;
		top: 10px;
		right: 260px;
	}
	36% {
		opacity: 1;
		top: 10px;
		right: 260px;
	}
	41% {
		opacity: 1;
		top: 10;
		right: 730px;
	}
	100% {
		opacity: 1;
		top: 10px;
		right: 730px;
	}
}

@-ms-keyframes fortyk-txt {
	0% {
		opacity: 0;
	}
	41% {
		opacity: 1;
	}
	42% {
		opacity: 1;
		top: 10px;
		right: -290px;
	}
	44% {
		opacity: 1;
		top: 10px;
		right: 85px;
	}
	60% {
		opacity: 1;
		top: 10px;
		right: 85px;
	}
	65% {
		opacity: 1;
		top: 10;
		right: 730px;
	}
	100% {
		opacity: 1;
		top: 10px;
		right: 730px;
	}
}

@-ms-keyframes quote-txt {
	0% {
		opacity: 0;
	}
	65% {
		opacity: 1;
	}
	66% {
		opacity: 1;
		top: 10px;
		right: -395px;
	}
	71% {
		opacity: 1;
		top: 10px;
		right: 295px;
	}
	88% {
		opacity: 1;
		top: 10px;
		right: 295px;
	}
	89% {
		opacity: 1;
		top:-45px;
		right: 295px;
	}
	100% {
		opacity: 1;
		top:-45px;
		right: 295px;
	}
}

@-ms-keyframes first-card {
	0% {
		opacity: 0;
	}
	15% {
		opacity: 1;
	}
	16% {
		opacity: 1;
		top: 7px;
		left: -92px;
	}
	78% {
		opacity: 1;
		top: 7px;
		left: 480px;
	}
	88% {
		opacity: 1;
		top: 7px;
		left: 480px;
	}
	89% {
		opacity: 1;
		top: -58px;
		left: 480px;
	}
	100% {
		opacity: 1;
		top: -58px;
		left: 480px;
	}
}

@-ms-keyframes cloud-overlay {
	0% {
		opacity: 0;
		top: 30px;
		left: -140px;
	}
	88% {
		opacity: 1;
		top: 30px;
		left: -140px;
	}
	90% {
		opacity: 1;
		top: -290px;
		left: -140px;
	}
	100% {
		opacity: 1;
		top: -290px;
		left: -140px;
	}
}

@-ms-keyframes beach {
	0% {
		opacity: 0;
	}
	89% {
		opacity: 0;
		bottom: -60px;
	}
	91% {
		opacity: 1;
		bottom: 0px;
	}
	100% {
		opacity: 1;
		bottom: 0px;
	}
}

@-ms-keyframes palm-tree {
	0% {
		opacity: 0;
	}
	89% {
		opacity: 0;
		bottom: -60px;
		left: 0;
	}
	91% {
		opacity: 1;
		bottom: 0px;
		left: 0;
	}
	95% {
		opacity: 1;
		bottom: 0px;
		left: 0;
	}	
	99% {
		opacity: 1;
		bottom: 0px;
		left: -74px;
	}
	100% {
		opacity: 1;
		bottom: 0px;
		left: -74px;
	}	
}

@-ms-keyframes second-card {
	0% {
		opacity: 0;
	}
	89% {
		opacity: 0;
		top: 67px;
		right: 30px;
	}
	91% {
		opacity: 1;
		top: 7px;
		right: 30px;
	}
	95% {
		opacity: 1;
		top: 7px;
		right: 30px;
	}
	99% {
		opacity: 1;
		top: 7px;
		right: 18px;
	}
	100% {
		opacity: 1;
		top: 7px;
		right: 18px;
	}
}

@-ms-keyframes two-txt-end {
	0% {
		opacity: 0;
	}

	91% {
		opacity: 0;
		top: 70px;
		left: 75px;
	}
	92% {
		opacity: 1;
		top: 10px;
		left: 75px;
	}
	92% {
		opacity: 1;
		top: 10px;
		left: 75px;
	}
	95% {
		opacity: 1;
		top: 10px;
		left: 75px;
	}
	99% {
		opacity: 1;
		top: 10px;
		left: 11px;
	}
	100% {
		opacity: 1;
		top: 10px;
		left: 11px;
	}
}

@-ms-keyframes fortyk-txt-end {
	0% {
		opacity: 0;
	}

	91% {
		opacity: 0;
		top: 70px;
		left: 310px;
	}
	92% {
		opacity: 1;
		top: 10px;
		left: 310px;
	}
	95% {
		opacity: 1;
		top: 10px;
		left: 310px;
	}
	99% {
		opacity: 1;
		top: 10px;
		left: 241px;
	}
	100% {
		opacity: 1;
		top: 10px;
		left: 241px;
	}
}

@-ms-keyframes award {
	0% {
		opacity: 0;
		right: -83px;
	}
	96% {
		opacity: 1;
		right: -83px;
	}
	99% {
		opacity: 1;
		right: 110px;
	}
	100% {
		opacity: 1;
		right: 110px;
	}
}

/* O Animations */

@-o-keyframes barclaytext-animation {
	0%   {
		opacity: 0.5; 
		width: 212px;
		top: 30px;
		left: 250px;
	}
	10% {
		opacity: 1;
		width: 500px;
		top: 25px;
		left: 100px;
	}
	80% {
		opacity: 1;
		width: 559px;
		top: 25px;
		left: 80px;
	}
	100% {
		opacity: 1;
		width: 13974px;
		left: -165px;
		top: -590px;
	}
}

@-o-keyframes bluebg {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes clouds {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
		top: 40px;
		left: -450px;
	}
	11% {
		opacity: 1;
	}
	88% {
		opacity: 1;
		top: 40px;
		left: -630px;
	}
	89% {
		opacity: 1;
		top: 40px;
		left: -630px;
	}
	90% {
		opacity: 0;
		top: 40px;
		left: -630px;
	}
	100% {
		opacity: 0;
		top: 95px;
		left: -630px;
	}
}

@-o-keyframes terms {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes two-txt {
	0% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	12% {
		opacity: 1;
		top: 10px;
		right: -226px;
	}
	14% {
		opacity: 1;
		top: 10px;
		right: 260px;
	}
	36% {
		opacity: 1;
		top: 10px;
		right: 260px;
	}
	41% {
		opacity: 1;
		top: 10;
		right: 730px;
	}
	100% {
		opacity: 1;
		top: 10px;
		right: 730px;
	}
}

@-o-keyframes fortyk-txt {
	0% {
		opacity: 0;
	}
	41% {
		opacity: 1;
	}
	42% {
		opacity: 1;
		top: 10px;
		right: -290px;
	}
	44% {
		opacity: 1;
		top: 10px;
		right: 85px;
	}
	60% {
		opacity: 1;
		top: 10px;
		right: 85px;
	}
	65% {
		opacity: 1;
		top: 10;
		right: 730px;
	}
	100% {
		opacity: 1;
		top: 10px;
		right: 730px;
	}
}

@-o-keyframes quote-txt {
	0% {
		opacity: 0;
	}
	65% {
		opacity: 1;
	}
	66% {
		opacity: 1;
		top: 10px;
		right: -395px;
	}
	71% {
		opacity: 1;
		top: 10px;
		right: 295px;
	}
	88% {
		opacity: 1;
		top: 10px;
		right: 295px;
	}
	89% {
		opacity: 1;
		top:-45px;
		right: 295px;
	}
	100% {
		opacity: 1;
		top:-45px;
		right: 295px;
	}
}

@-o-keyframes first-card {
	0% {
		opacity: 0;
	}
	15% {
		opacity: 1;
	}
	16% {
		opacity: 1;
		top: 7px;
		left: -92px;
	}
	78% {
		opacity: 1;
		top: 7px;
		left: 480px;
	}
	88% {
		opacity: 1;
		top: 7px;
		left: 480px;
	}
	89% {
		opacity: 1;
		top: -58px;
		left: 480px;
	}
	100% {
		opacity: 1;
		top: -58px;
		left: 480px;
	}
}

@-o-keyframes cloud-overlay {
	0% {
		opacity: 0;
		top: 30px;
		left: -140px;
	}
	88% {
		opacity: 1;
		top: 30px;
		left: -140px;
	}
	90% {
		opacity: 1;
		top: -290px;
		left: -140px;
	}
	100% {
		opacity: 1;
		top: -290px;
		left: -140px;
	}
}

@-o-keyframes beach {
	0% {
		opacity: 0;
	}
	89% {
		opacity: 0;
		bottom: -60px;
	}
	91% {
		opacity: 1;
		bottom: 0px;
	}
	100% {
		opacity: 1;
		bottom: 0px;
	}
}

@-o-keyframes palm-tree {
	0% {
		opacity: 0;
	}
	89% {
		opacity: 0;
		bottom: -60px;
		left: 0;
	}
	91% {
		opacity: 1;
		bottom: 0px;
		left: 0;
	}
	95% {
		opacity: 1;
		bottom: 0px;
		left: 0;
	}	
	99% {
		opacity: 1;
		bottom: 0px;
		left: -74px;
	}
	100% {
		opacity: 1;
		bottom: 0px;
		left: -74px;
	}	
}

@-o-keyframes second-card {
	0% {
		opacity: 0;
	}
	89% {
		opacity: 0;
		top: 67px;
		right: 30px;
	}
	91% {
		opacity: 1;
		top: 7px;
		right: 30px;
	}
	95% {
		opacity: 1;
		top: 7px;
		right: 30px;
	}
	99% {
		opacity: 1;
		top: 7px;
		right: 18px;
	}
	100% {
		opacity: 1;
		top: 7px;
		right: 18px;
	}
}

@-o-keyframes two-txt-end {
	0% {
		opacity: 0;
	}

	91% {
		opacity: 0;
		top: 70px;
		left: 75px;
	}
	92% {
		opacity: 1;
		top: 10px;
		left: 75px;
	}
	92% {
		opacity: 1;
		top: 10px;
		left: 75px;
	}
	95% {
		opacity: 1;
		top: 10px;
		left: 75px;
	}
	99% {
		opacity: 1;
		top: 10px;
		left: 11px;
	}
	100% {
		opacity: 1;
		top: 10px;
		left: 11px;
	}
}

@-o-keyframes fortyk-txt-end {
	0% {
		opacity: 0;
	}

	91% {
		opacity: 0;
		top: 70px;
		left: 310px;
	}
	92% {
		opacity: 1;
		top: 10px;
		left: 310px;
	}
	95% {
		opacity: 1;
		top: 10px;
		left: 310px;
	}
	99% {
		opacity: 1;
		top: 10px;
		left: 241px;
	}
	100% {
		opacity: 1;
		top: 10px;
		left: 241px;
	}
}

@-o-keyframes award {
	0% {
		opacity: 0;
		right: -83px;
	}
	96% {
		opacity: 1;
		right: -83px;
	}
	99% {
		opacity: 1;
		right: 110px;
	}
	100% {
		opacity: 1;
		right: 110px;
	}
}

/* Animations */

@keyframes barclaytext-animation {
	0%   {
		opacity: 0.5; 
		width: 212px;
		top: 30px;
		left: 250px;
	}
	10% {
		opacity: 1;
		width: 500px;
		top: 25px;
		left: 100px;
	}
	80% {
		opacity: 1;
		width: 559px;
		top: 25px;
		left: 80px;
	}
	100% {
		opacity: 1;
		width: 13974px;
		left: -165px;
		top: -590px;
	}
}

@keyframes bluebg {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@keyframes clouds {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
		top: 40px;
		left: -450px;
	}
	11% {
		opacity: 1;
	}
	88% {
		opacity: 1;
		top: 40px;
		left: -630px;
	}
	89% {
		opacity: 1;
		top: 40px;
		left: -630px;
	}
	90% {
		opacity: 0;
		top: 40px;
		left: -630px;
	}
	100% {
		opacity: 0;
		top: 95px;
		left: -630px;
	}
}

@keyframes terms {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@keyframes two-txt {
	0% {
		opacity: 0;
	}
	11% {
		opacity: 1;
	}
	12% {
		opacity: 1;
		top: 10px;
		right: -226px;
	}
	14% {
		opacity: 1;
		top: 10px;
		right: 260px;
	}
	36% {
		opacity: 1;
		top: 10px;
		right: 260px;
	}
	41% {
		opacity: 1;
		top: 10;
		right: 730px;
	}
	100% {
		opacity: 1;
		top: 10px;
		right: 730px;
	}
}

@keyframes fortyk-txt {
	0% {
		opacity: 0;
	}
	41% {
		opacity: 1;
	}
	42% {
		opacity: 1;
		top: 10px;
		right: -290px;
	}
	44% {
		opacity: 1;
		top: 10px;
		right: 85px;
	}
	60% {
		opacity: 1;
		top: 10px;
		right: 85px;
	}
	65% {
		opacity: 1;
		top: 10;
		right: 730px;
	}
	100% {
		opacity: 1;
		top: 10px;
		right: 730px;
	}
}

@keyframes quote-txt {
	0% {
		opacity: 0;
	}
	65% {
		opacity: 1;
	}
	66% {
		opacity: 1;
		top: 10px;
		right: -395px;
	}
	71% {
		opacity: 1;
		top: 10px;
		right: 295px;
	}
	88% {
		opacity: 1;
		top: 10px;
		right: 295px;
	}
	89% {
		opacity: 1;
		top:-45px;
		right: 295px;
	}
	100% {
		opacity: 1;
		top:-45px;
		right: 295px;
	}
}

@keyframes first-card {
	0% {
		opacity: 0;
	}
	15% {
		opacity: 1;
	}
	16% {
		opacity: 1;
		top: 7px;
		left: -92px;
	}
	78% {
		opacity: 1;
		top: 7px;
		left: 480px;
	}
	88% {
		opacity: 1;
		top: 7px;
		left: 480px;
	}
	89% {
		opacity: 1;
		top: -58px;
		left: 480px;
	}
	100% {
		opacity: 1;
		top: -58px;
		left: 480px;
	}
}

@keyframes cloud-overlay {
	0% {
		opacity: 0;
		top: 30px;
		left: -140px;
	}
	88% {
		opacity: 1;
		top: 30px;
		left: -140px;
	}
	90% {
		opacity: 1;
		top: -290px;
		left: -140px;
	}
	100% {
		opacity: 1;
		top: -290px;
		left: -140px;
	}
}

@keyframes beach {
	0% {
		opacity: 0;
	}
	89% {
		opacity: 0;
		bottom: -60px;
	}
	91% {
		opacity: 1;
		bottom: 0px;
	}
	100% {
		opacity: 1;
		bottom: 0px;
	}
}

@keyframes palm-tree {
	0% {
		opacity: 0;
	}
	89% {
		opacity: 0;
		bottom: -60px;
		left: 0;
	}
	91% {
		opacity: 1;
		bottom: 0px;
		left: 0;
	}
	95% {
		opacity: 1;
		bottom: 0px;
		left: 0;
	}	
	99% {
		opacity: 1;
		bottom: 0px;
		left: -74px;
	}
	100% {
		opacity: 1;
		bottom: 0px;
		left: -74px;
	}	
}

@keyframes second-card {
	0% {
		opacity: 0;
	}
	89% {
		opacity: 0;
		top: 67px;
		right: 30px;
	}
	91% {
		opacity: 1;
		top: 7px;
		right: 30px;
	}
	95% {
		opacity: 1;
		top: 7px;
		right: 30px;
	}
	99% {
		opacity: 1;
		top: 7px;
		right: 18px;
	}
	100% {
		opacity: 1;
		top: 7px;
		right: 18px;
	}
}

@keyframes two-txt-end {
	0% {
		opacity: 0;
	}

	91% {
		opacity: 0;
		top: 70px;
		left: 75px;
	}
	92% {
		opacity: 1;
		top: 10px;
		left: 75px;
	}
	92% {
		opacity: 1;
		top: 10px;
		left: 75px;
	}
	95% {
		opacity: 1;
		top: 10px;
		left: 75px;
	}
	99% {
		opacity: 1;
		top: 10px;
		left: 11px;
	}
	100% {
		opacity: 1;
		top: 10px;
		left: 11px;
	}
}

@keyframes fortyk-txt-end {
	0% {
		opacity: 0;
	}

	91% {
		opacity: 0;
		top: 70px;
		left: 310px;
	}
	92% {
		opacity: 1;
		top: 10px;
		left: 310px;
	}
	95% {
		opacity: 1;
		top: 10px;
		left: 310px;
	}
	99% {
		opacity: 1;
		top: 10px;
		left: 241px;
	}
	100% {
		opacity: 1;
		top: 10px;
		left: 241px;
	}
}

@keyframes award {
	0% {
		opacity: 0;
		right: -83px;
	}
	96% {
		opacity: 1;
		right: -83px;
	}
	99% {
		opacity: 1;
		right: 110px;
	}
	100% {
		opacity: 1;
		right: 110px;
	}
}
