/*!
 * animsition v4.0.2
 * A simple and easy jQuery plugin for CSS animated page transitions.
 * http://blivesta.github.io/animsition
 * License : MIT
 * Author : blivesta (http://blivesta.com/)
 */
.animsition,
.animsition-overlay {
  position: relative;
  opacity: 0;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

/**
 * overlay option
 */
.animsition-overlay-slide {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #ddd;
}
/* loading option */
.animsition-loading,
.animsition-loading:after {
  width: 32px;
  height: 32px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: -16px;
  border-radius: 50%;
  z-index: 100;
}

.animsition-loading {
  background-color: transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.2);
  border-right: 5px solid rgba(0, 0, 0, 0.2);
  border-bottom: 5px solid rgba(0, 0, 0, 0.2);
  border-left: 5px solid #eee;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-animation-iteration-count:infinite;
          animation-iteration-count:infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-duration: .8s;
          animation-duration: .8s;
  -webkit-animation-name: animsition-loading;
          animation-name: animsition-loading;
}

@-webkit-keyframes animsition-loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes animsition-loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.fade-in {
  -webkit-animation-name: fade-in;
          animation-name: fade-in;
}


@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.fade-out {
  -webkit-animation-name: fade-out;
          animation-name: fade-out;
}
@-webkit-keyframes fade-in-up {
  0% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-up {
  0% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}


.fade-in-up {
  -webkit-animation-name: fade-in-up;
          animation-name: fade-in-up;
}


@-webkit-keyframes fade-out-up {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    opacity: 0;
  }
}


@keyframes fade-out-up {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    opacity: 0;
  }
}


.fade-out-up {
  -webkit-animation-name: fade-out-up;
          animation-name: fade-out-up;
}
@-webkit-keyframes fade-in-up-sm {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-up-sm {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}


.fade-in-up-sm {
  -webkit-animation-name: fade-in-up-sm;
          animation-name: fade-in-up-sm;
}


@-webkit-keyframes fade-out-up-sm {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
    opacity: 0;
  }
}


@keyframes fade-out-up-sm {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
    opacity: 0;
  }
}


.fade-out-up-sm {
  -webkit-animation-name: fade-out-up-sm;
          animation-name: fade-out-up-sm;
}
@-webkit-keyframes fade-in-up-lg {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-up-lg {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}


.fade-in-up-lg {
  -webkit-animation-name: fade-in-up-lg;
          animation-name: fade-in-up-lg;
}


@-webkit-keyframes fade-out-up-lg {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
}


@keyframes fade-out-up-lg {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
}


.fade-out-up-lg {
  -webkit-animation-name: fade-out-up-lg;
          animation-name: fade-out-up-lg;
}
@-webkit-keyframes fade-in-down {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-down {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}


.fade-in-down {
  -webkit-animation-name: fade-in-down;
          animation-name: fade-in-down;
}


@-webkit-keyframes fade-out-down {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    opacity: 0;
  }
}


@keyframes fade-out-down {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    opacity: 0;
  }
}


.fade-out-down {
  -webkit-animation-name: fade-out-down;
          animation-name: fade-out-down;
}
@-webkit-keyframes fade-in-down-sm {
  0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-down-sm {
  0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}


.fade-in-down-sm {
  -webkit-animation-name: fade-in-down-sm;
          animation-name: fade-in-down-sm;
}


@-webkit-keyframes fade-out-down-sm {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0;
  }
}


@keyframes fade-out-down-sm {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0;
  }
}


.fade-out-down-sm {
  -webkit-animation-name: fade-out-down-sm;
          animation-name: fade-out-down-sm;
}
@-webkit-keyframes fade-in-down-lg {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-down-lg {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}


.fade-in-down-lg {
  -webkit-animation-name: fade-in-down;
          animation-name: fade-in-down;
}


@-webkit-keyframes fade-out-down-lg {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
}


@keyframes fade-out-down-lg {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
}


.fade-out-down-lg {
  -webkit-animation-name: fade-out-down-lg;
          animation-name: fade-out-down-lg;
}
@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-500px);
            transform: translateX(-500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-500px);
            transform: translateX(-500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


.fade-in-left {
  -webkit-animation-name: fade-in-left;
          animation-name: fade-in-left;
}


@-webkit-keyframes fade-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-500px);
            transform: translateX(-500px);
    opacity: 0;
  }
}


@keyframes fade-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-500px);
            transform: translateX(-500px);
    opacity: 0;
  }
}


.fade-out-left {
  -webkit-animation-name: fade-out-left;
          animation-name: fade-out-left;
}
@-webkit-keyframes fade-in-left-sm {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left-sm {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


.fade-in-left-sm {
  -webkit-animation-name: fade-in-left-sm;
          animation-name: fade-in-left-sm;
}


@-webkit-keyframes fade-out-left-sm {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
}


@keyframes fade-out-left-sm {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
}


.fade-out-left-sm {
  -webkit-animation-name: fade-out-left-sm;
          animation-name: fade-out-left-sm;
}
@-webkit-keyframes fade-in-left-lg {
  0% {
    -webkit-transform: translateX(-1500px);
            transform: translateX(-1500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left-lg {
  0% {
    -webkit-transform: translateX(-1500px);
            transform: translateX(-1500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


.fade-in-left-lg {
  -webkit-animation-name: fade-in-left-lg;
          animation-name: fade-in-left-lg;
}


@-webkit-keyframes fade-out-left-lg {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1500px);
            transform: translateX(-1500px);
    opacity: 0;
  }
}


@keyframes fade-out-left-lg {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1500px);
            transform: translateX(-1500px);
    opacity: 0;
  }
}


.fade-out-left-lg {
  -webkit-animation-name: fade-out-left-lg;
          animation-name: fade-out-left-lg;
}
@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(500px);
            transform: translateX(500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(500px);
            transform: translateX(500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


.fade-in-right {
  -webkit-animation-name: fade-in-right;
          animation-name: fade-in-right;
}


@-webkit-keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(500px);
            transform: translateX(500px);
    opacity: 0;
  }
}


@keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(500px);
            transform: translateX(500px);
    opacity: 0;
  }
}


.fade-out-right {
  -webkit-animation-name: fade-out-right;
          animation-name: fade-out-right;
}
@-webkit-keyframes fade-in-right-sm {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right-sm {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


.fade-in-right-sm {
  -webkit-animation-name: fade-in-right-sm;
          animation-name: fade-in-right-sm;
}


@-webkit-keyframes fade-out-right-sm {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
}


@keyframes fade-out-right-sm {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
}


.fade-out-right-sm {
  -webkit-animation-name: fade-out-right-sm;
          animation-name: fade-out-right-sm;
}
@-webkit-keyframes fade-in-right-lg {
  0% {
    -webkit-transform: translateX(1500px);
            transform: translateX(1500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right-lg {
  0% {
    -webkit-transform: translateX(1500px);
            transform: translateX(1500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


.fade-in-right-lg {
  -webkit-animation-name: fade-in-right-lg;
          animation-name: fade-in-right-lg;
}


@-webkit-keyframes fade-out-right-lg {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1500px);
            transform: translateX(1500px);
    opacity: 0;
  }
}


@keyframes fade-out-right-lg {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1500px);
            transform: translateX(1500px);
    opacity: 0;
  }
}


.fade-out-right-lg {
  -webkit-animation-name: fade-out-right-lg;
          animation-name: fade-out-right-lg;
}

@-webkit-keyframes rotate-in {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 1;
  }
}

@keyframes rotate-in {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 1;
  }
}


.rotate-in {
  -webkit-animation-name: rotate-in;
          animation-name: rotate-in;
}


@-webkit-keyframes rotate-out {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 0;
  }
}


@keyframes rotate-out {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 0;
  }
}


.rotate-out {
  -webkit-animation-name: rotate-out;
          animation-name: rotate-out;
}
@-webkit-keyframes rotate-in-sm {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 1;
  }
}
@keyframes rotate-in-sm {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 1;
  }
}


.rotate-in-sm {
  -webkit-animation-name: rotate-in-sm;
          animation-name: rotate-in-sm;
}


@-webkit-keyframes rotate-out-sm {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 0;
  }
}


@keyframes rotate-out-sm {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 0;
  }
}


.rotate-out-sm {
  -webkit-animation-name: rotate-out-sm;
          animation-name: rotate-out-sm;
}
@-webkit-keyframes rotate-in-lg {
  0% {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 1;
  }
}
@keyframes rotate-in-lg {
  0% {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 1;
  }
}


.rotate-in-lg {
  -webkit-animation-name: rotate-in-lg;
          animation-name: rotate-in-lg;
}


@-webkit-keyframes rotate-out-lg {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 0;
  }
}


@keyframes rotate-out-lg {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    opacity: 0;
  }
}


.rotate-out-lg {
  -webkit-animation-name: rotate-out-lg;
          animation-name: rotate-out-lg;
}

@-webkit-keyframes flip-in-x {
  0% {
    -webkit-transform: perspective(550px) rotateX(90deg);
            transform: perspective(550px) rotateX(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: perspective(550px) rotateX(0deg);
            transform: perspective(550px) rotateX(0deg);
    opacity: 1;
  }
}

@keyframes flip-in-x {
  0% {
    -webkit-transform: perspective(550px) rotateX(90deg);
            transform: perspective(550px) rotateX(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: perspective(550px) rotateX(0deg);
            transform: perspective(550px) rotateX(0deg);
    opacity: 1;
  }
}


.flip-in-x {
  -webkit-animation-name: flip-in-x;
          animation-name: flip-in-x;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}


@-webkit-keyframes flip-out-x {
  0% {
    -webkit-transform: perspective(550px) rotateX(0deg);
            transform: perspective(550px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(550px) rotateX(90deg);
            transform: perspective(550px) rotateX(90deg);
    opacity: 0;
  }
}


@keyframes flip-out-x {
  0% {
    -webkit-transform: perspective(550px) rotateX(0deg);
            transform: perspective(550px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(550px) rotateX(90deg);
            transform: perspective(550px) rotateX(90deg);
    opacity: 0;
  }
}


.flip-out-x {
  -webkit-animation-name: flip-out-x;
          animation-name: flip-out-x;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-in-x-nr {
  0% {
    -webkit-transform: perspective(100px) rotateX(90deg);
            transform: perspective(100px) rotateX(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: perspective(100px) rotateX(0deg);
            transform: perspective(100px) rotateX(0deg);
    opacity: 1;
  }
}
@keyframes flip-in-x-nr {
  0% {
    -webkit-transform: perspective(100px) rotateX(90deg);
            transform: perspective(100px) rotateX(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: perspective(100px) rotateX(0deg);
            transform: perspective(100px) rotateX(0deg);
    opacity: 1;
  }
}


.flip-in-x-nr {
  -webkit-animation-name: flip-in-x-nr;
          animation-name: flip-in-x-nr;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}


@-webkit-keyframes flip-out-x-nr {
  0% {
    -webkit-transform: perspective(100px) rotateX(0deg);
            transform: perspective(100px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(100px) rotateX(90deg);
            transform: perspective(100px) rotateX(90deg);
    opacity: 0;
  }
}


@keyframes flip-out-x-nr {
  0% {
    -webkit-transform: perspective(100px) rotateX(0deg);
            transform: perspective(100px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(100px) rotateX(90deg);
            transform: perspective(100px) rotateX(90deg);
    opacity: 0;
  }
}


.flip-out-x-nr {
  -webkit-animation-name: flip-out-x-nr;
          animation-name: flip-out-x-nr;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-in-x-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateX(90deg);
            transform: perspective(1000px) rotateX(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: perspective(1000px) rotateX(0deg);
            transform: perspective(1000px) rotateX(0deg);
    opacity: 1;
  }
}
@keyframes flip-in-x-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateX(90deg);
            transform: perspective(1000px) rotateX(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: perspective(1000px) rotateX(0deg);
            transform: perspective(1000px) rotateX(0deg);
    opacity: 1;
  }
}


.flip-in-x-fr {
  -webkit-animation-name: flip-in-x-fr;
          animation-name: flip-in-x-fr;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}


@-webkit-keyframes flip-out-x-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateX(0deg);
            transform: perspective(1000px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(1000px) rotateX(90deg);
            transform: perspective(1000px) rotateX(90deg);
    opacity: 0;
  }
}


@keyframes flip-out-x-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateX(0deg);
            transform: perspective(1000px) rotateX(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(1000px) rotateX(90deg);
            transform: perspective(1000px) rotateX(90deg);
    opacity: 0;
  }
}


.flip-out-x-fr {
  -webkit-animation-name: flip-out-x-fr;
          animation-name: flip-out-x-fr;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-in-y {
  0% {
    -webkit-transform: perspective(550px) rotateY(90deg);
            transform: perspective(550px) rotateY(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: perspective(550px) rotateY(0deg);
            transform: perspective(550px) rotateY(0deg);
    opacity: 1;
  }
}
@keyframes flip-in-y {
  0% {
    -webkit-transform: perspective(550px) rotateY(90deg);
            transform: perspective(550px) rotateY(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: perspective(550px) rotateY(0deg);
            transform: perspective(550px) rotateY(0deg);
    opacity: 1;
  }
}


.flip-in-y {
  -webkit-animation-name: flip-in-y;
          animation-name: flip-in-y;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}


@-webkit-keyframes flip-out-y {
  0% {
    -webkit-transform: perspective(550px) rotateY(0deg);
            transform: perspective(550px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(550px) rotateY(90deg);
            transform: perspective(550px) rotateY(90deg);
    opacity: 0;
  }
}


@keyframes flip-out-y {
  0% {
    -webkit-transform: perspective(550px) rotateY(0deg);
            transform: perspective(550px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(550px) rotateY(90deg);
            transform: perspective(550px) rotateY(90deg);
    opacity: 0;
  }
}


.flip-out-y {
  -webkit-animation-name: flip-out-y;
          animation-name: flip-out-y;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-in-y-nr {
  0% {
    -webkit-transform: perspective(100px) rotateY(90deg);
            transform: perspective(100px) rotateY(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: perspective(100px) rotateY(0deg);
            transform: perspective(100px) rotateY(0deg);
    opacity: 1;
  }
}
@keyframes flip-in-y-nr {
  0% {
    -webkit-transform: perspective(100px) rotateY(90deg);
            transform: perspective(100px) rotateY(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: perspective(100px) rotateY(0deg);
            transform: perspective(100px) rotateY(0deg);
    opacity: 1;
  }
}


.flip-in-y-nr {
  -webkit-animation-name: flip-in-y-nr;
          animation-name: flip-in-y-nr;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}


@-webkit-keyframes flip-out-y-nr {
  0% {
    -webkit-transform: perspective(100px) rotateY(0deg);
            transform: perspective(100px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(100px) rotateY(90deg);
            transform: perspective(100px) rotateY(90deg);
    opacity: 0;
  }
}


@keyframes flip-out-y-nr {
  0% {
    -webkit-transform: perspective(100px) rotateY(0deg);
            transform: perspective(100px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(100px) rotateY(90deg);
            transform: perspective(100px) rotateY(90deg);
    opacity: 0;
  }
}


.flip-out-y-nr {
  -webkit-animation-name: flip-out-y-nr;
          animation-name: flip-out-y-nr;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}
@-webkit-keyframes flip-in-y-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateY(90deg);
            transform: perspective(1000px) rotateY(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: perspective(1000px) rotateY(0deg);
            transform: perspective(1000px) rotateY(0deg);
    opacity: 1;
  }
}
@keyframes flip-in-y-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateY(90deg);
            transform: perspective(1000px) rotateY(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: perspective(1000px) rotateY(0deg);
            transform: perspective(1000px) rotateY(0deg);
    opacity: 1;
  }
}


.flip-in-y-fr {
  -webkit-animation-name: flip-in-y-fr;
          animation-name: flip-in-y-fr;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}


@-webkit-keyframes flip-out-y-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateY(0deg);
            transform: perspective(1000px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(1000px) rotateY(90deg);
            transform: perspective(1000px) rotateY(90deg);
    opacity: 0;
  }
}


@keyframes flip-out-y-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateY(0deg);
            transform: perspective(1000px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(1000px) rotateY(90deg);
            transform: perspective(1000px) rotateY(90deg);
    opacity: 0;
  }
}


.flip-out-y-fr {
  -webkit-animation-name: flip-out-y-fr;
          animation-name: flip-out-y-fr;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
}

@-webkit-keyframes zoom-in {
  0% {
    -webkit-transform: scale(.7);
            transform: scale(.7);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoom-in {
  0% {
    -webkit-transform: scale(.7);
            transform: scale(.7);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.zoom-in {
  -webkit-animation-name: zoom-in;
          animation-name: zoom-in;
}


@-webkit-keyframes zoom-out {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(.7);
            transform: scale(.7);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}


@keyframes zoom-out {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(.7);
            transform: scale(.7);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}


.zoom-out {
  -webkit-animation-name: zoom-out;
          animation-name: zoom-out;
}
@-webkit-keyframes zoom-in-sm {
  0% {
    -webkit-transform: scale(.95);
            transform: scale(.95);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes zoom-in-sm {
  0% {
    -webkit-transform: scale(.95);
            transform: scale(.95);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.zoom-in-sm {
  -webkit-animation-name: zoom-in-sm;
          animation-name: zoom-in-sm;
}


@-webkit-keyframes zoom-out-sm {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(.95);
            transform: scale(.95);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}


@keyframes zoom-out-sm {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(.95);
            transform: scale(.95);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}


.zoom-out-sm {
  -webkit-animation-name: zoom-out-sm;
          animation-name: zoom-out-sm;
}
@-webkit-keyframes zoom-in-lg {
  0% {
    -webkit-transform: scale(.4);
            transform: scale(.4);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes zoom-in-lg {
  0% {
    -webkit-transform: scale(.4);
            transform: scale(.4);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.zoom-in-lg {
  -webkit-animation-name: zoom-in-lg;
          animation-name: zoom-in-lg;
}


@-webkit-keyframes zoom-out-lg {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(.4);
            transform: scale(.4);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}


@keyframes zoom-out-lg {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(.4);
            transform: scale(.4);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}


.zoom-out-lg {
  -webkit-animation-name: zoom-out-lg;
          animation-name: zoom-out-lg;
}

@-webkit-keyframes overlay-slide-in-top {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}

@keyframes overlay-slide-in-top {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}


.overlay-slide-in-top {
  top: 0;
  height: 0;
  -webkit-animation-name: overlay-slide-in-top;
          animation-name: overlay-slide-in-top;
}

@-webkit-keyframes overlay-expand-in {
  0% {
    width: 0;
    height: 5px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    opacity: 1;
  }
  40%{
    height: 5px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    width: 100%;
    opacity: 1;
  }
  80% {
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    height: 100%;
    width: 100%;
  }
}

@keyframes overlay-expand-in {
  0% {
    width: 0;
    height: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: 1;
  }
  40%{
    height: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    opacity: 1;
  }
  80% {
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 100%;
    width: 100%;
  }
}


.overlay-expand-in {
  top: 0;
  height: 0;
  -webkit-animation-name: overlay-expand-in;
          animation-name: overlay-expand-in;
}

@-webkit-keyframes overlay-expand-out {
  0% {
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    height: 100%;
    width: 100%;
  }
  40% {
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    opacity: 1;
  }
  80%{
    height: 5px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    width: 100%;
    opacity: 1;
  }
  100% {
    width: 0;
    height: 5px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    opacity: 1;
  }
}

@keyframes overlay-expand-out {
  0% {
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 100%;
    width: 100%;
  }
  40% {
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: 1;
  }
  80%{
    height: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    opacity: 1;
  }
  100% {
    width: 0;
    height: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: 1;
  }
}


.overlay-expand-out {
  top: 0;
  height: 0;
  -webkit-animation-name: overlay-expand-out;
          animation-name: overlay-expand-out;
}

@-webkit-keyframes overlay-slide-out-top {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}


@keyframes overlay-slide-out-top {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}


.overlay-slide-out-top {
  top: 0;
  height: 100%;
  -webkit-animation-name: overlay-slide-out-top;
          animation-name: overlay-slide-out-top;
}
@-webkit-keyframes overlay-slide-in-bottom {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
@keyframes overlay-slide-in-bottom {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}


.overlay-slide-in-bottom {
  bottom: 0;
  height: 0;
  -webkit-animation-name: overlay-slide-in-bottom;
          animation-name: overlay-slide-in-bottom;
}


@-webkit-keyframes overlay-slide-out-bottom {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}


@keyframes overlay-slide-out-bottom {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}


.overlay-slide-out-bottom {
  bottom: 0;
  height: 100%;
  -webkit-animation-name: overlay-slide-out-bottom;
          animation-name: overlay-slide-out-bottom;
}
@-webkit-keyframes overlay-slide-in-left {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
@keyframes overlay-slide-in-left {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}


.overlay-slide-in-left {
  width: 0;
  -webkit-animation-name: overlay-slide-in-left;
          animation-name: overlay-slide-in-left;
}


@-webkit-keyframes overlay-slide-out-left {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}


@keyframes overlay-slide-out-left {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}


.overlay-slide-out-left {
  left: 0;
  width: 100%;
  -webkit-animation-name: overlay-slide-out-left;
          animation-name: overlay-slide-out-left;
}
@-webkit-keyframes overlay-slide-in-right {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
@keyframes overlay-slide-in-right {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}


.overlay-slide-in-right {
  right: 0;
  width: 0;
  -webkit-animation-name: overlay-slide-in-right;
          animation-name: overlay-slide-in-right;
}


@-webkit-keyframes overlay-slide-out-right {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}


@keyframes overlay-slide-out-right {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}


.overlay-slide-out-right {
  right: 0;
  width: 100%;
  -webkit-animation-name: overlay-slide-out-right;
          animation-name: overlay-slide-out-right;
}
