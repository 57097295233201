#slide_content_wrapper, .overlay_content_wrapper {
	height: 100%;
	width: 100% !important;
	overflow: hidden;
	position: relative;
}

.container {
	position: absolute;
	overflow: hidden;
	@include center;
	transition: .3s ease-in-out;
	opacity: 0;

	.animate & {
		opacity: 1;
	}

	.carousel-text-container {
		position: absolute;
		@include center;
		left: 28%;
		color: white;
		text-align: center;

		@media screen and (max-width: $bp-desktop) {
			left: 29%;
		}

		@media screen and (max-width: $bp-desktop) {
			left: 34%;
			top: 60%;
		}

		i {
			font-size: 100px;
			margin-bottom: 30px;
			color: #444;
			@include shadow( darken($background,100%), 25, rgb(65, 65, 65) );
			height: 100%;
			opacity: 0.8;
			opacity: 0;
			transition: 0.6s ease-in-out;
			-ms-transition: 0.6s ease-in-out;
			-webkit-transition: 0.6s ease-in-out;
			-o-transition: 0.6s ease-in-out;

			.animate & {
				opacity: 1;
			}
		}

		.subheading {
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 4px;
			opacity: 0;
			transition: 0.6s ease-in-out;
			-ms-transition: 0.6s ease-in-out;
			-webkit-transition: 0.6s ease-in-out;
			-o-transition: 0.6s ease-in-out;
			transition-delay: .3s;
			-o-transition-delay: .3s;
			-webkit-transition-delay: .3s;
			-ms-transition-delay: .3s;

			@media screen and (max-width: $bp-desktop) {
				font-size: 14px;
				letter-spacing: 4px;
			}

			@media screen and (max-width: $bp-small) {
				font-size: 11px;
				letter-spacing: 2px;
			}

			.animate & {
				opacity: 0.8;
			}
		}
	}
}

.content-slider {
	width: 100%;
	height: 30px;
  }
  
  .slider {
	height: 30px;
	width: 100%;
	margin: 20px auto 0;
	overflow: visible;
	position: relative;
  }
  
  .mask {
	overflow: hidden;
	height: 30px;
  }
  
  .slider ul {
	margin: 0;
	padding: 0;
	position: relative;
  }
  
  .slider li {
	width: 100%;
	height: 30px;
	position: absolute;
	top: -180px;
	list-style: none;
  }
  
  .slider li.anim1 {
	animation: cycle 20s ease-in-out infinite;
  }
  
  .slider li.anim2 {
	animation: cycle2 20s ease-in-out infinite;
  }
  
  .slider li.anim3 {
	animation: cycle3 20s ease-in-out infinite;
  }
  
  .slider li.anim4 {
	animation: cycle4 20s ease-in-out infinite;
  }
  
  .slider li.anim5 {
	animation: cycle5 20s ease-in-out infinite;
  }

  .slider li.anim6 {
	animation: cycle6 20s ease-in-out infinite;
  }
  
//   .slider:hover li {
// 	animation-play-state: paused;
//   }
  
  @keyframes cycle {
	0% {
	  top: 0px;
	}
	4% {
	  top: 0px;
	}
	16% {
	  top: 0px;
	  opacity: 1;
	  z-index: 0;
	}
	20% {
	  top: 180px;
	  opacity: 0;
	  z-index: 0;
	}
	21% {
	  top: -180px;
	  opacity: 0;
	  z-index: -1;
	}
	50% {
	  top: -180px;
	  opacity: 0;
	  z-index: -1;
	}
	92% {
	  top: -180px;
	  opacity: 0;
	  z-index: 0;
	}
	96% {
	  top: -180px;
	  opacity: 0;
	}
	100% {
	  top: 0px;
	  opacity: 1;
	}
  }
  
  @keyframes cycle2 {
	0% {
	  top: -180px;
	  opacity: 0;
	}
	16% {
	  top: -180px;
	  opacity: 0;
	}
	20% {
	  top: 0px;
	  opacity: 1;
	}
	24% {
	  top: 0px;
	  opacity: 1;
	}
	36% {
	  top: 0px;
	  opacity: 1;
	  z-index: 0;
	}
	40% {
	  top: 180px;
	  opacity: 0;
	  z-index: 0;
	}
	41% {
	  top: -180px;
	  opacity: 0;
	  z-index: -1;
	}
	100% {
	  top: -180px;
	  opacity: 0;
	  z-index: -1;
	}
  }
  
  @keyframes cycle3 {
	0% {
	  top: -180px;
	  opacity: 0;
	}
	36% {
	  top: -180px;
	  opacity: 0;
	}
	40% {
	  top: 0px;
	  opacity: 1;
	}
	44% {
	  top: 0px;
	  opacity: 1;
	}
	56% {
	  top: 0px;
	  opacity: 1;
	  z-index: 0;
	}
	60% {
	  top: 180px;
	  opacity: 0;
	  z-index: 0;
	}
	61% {
	  top: -180px;
	  opacity: 0;
	  z-index: -1;
	}
	100% {
	  top: -180px;
	  opacity: 0;
	  z-index: -1;
	}
  }
  
  @keyframes cycle4 {
	0% {
	  top: -180px;
	  opacity: 0;
	}
	56% {
	  top: -180px;
	  opacity: 0;
	}
	60% {
	  top: 0px;
	  opacity: 1;
	}
	64% {
	  top: 0px;
	  opacity: 1;
	}
	76% {
	  top: 0px;
	  opacity: 1;
	  z-index: 0;
	}
	80% {
	  top: 180px;
	  opacity: 0;
	  z-index: 0;
	}
	81% {
	  top: -180px;
	  opacity: 0;
	  z-index: -1;
	}
	100% {
	  top: -180px;
	  opacity: 0;
	  z-index: -1;
	}
  }
  
  @keyframes cycle5 {
	0% {
	  top: -180px;
	  opacity: 0;
	}
	76% {
	  top: -180px;
	  opacity: 0;
	}
	80% {
	  top: 0px;
	  opacity: 1;
	}
	84% {
	  top: 0px;
	  opacity: 1;
	}
	96% {
	  top: 0px;
	  opacity: 1;
	  z-index: 0;
	}
	100% {
	  top: 180px;
	  opacity: 0;
	  z-index: 0;
	}
  }

.intro-heading {
	color: white;
	padding: 5px 0;
	font-weight: 600;
	font-size: 50px;
	text-transform: uppercase;
	letter-spacing: 8px;
	overflow: hidden;
	transition: .2s ease-in-out;
	-o-transition: .2s ease-in-out;
	-ms-transition: .2s ease-in-out;
	-webkit-transition: .2s ease-in-out;

	@media screen and (max-width: $bp-desktop) {
		font-size: 30px;
		letter-spacing: 6px;
		padding: 0px;
	}

		@media screen and (max-width: $bp-small) {
			font-size: 20px;
			letter-spacing: 4px;
			padding: 0px;
			margin-bottom: -5px;
		}
  }
  
  .intro-heading .letter {
	display: inline-block;
	line-height: 1em;
  }