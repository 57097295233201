.menu-wrapper {
    width: 70px;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 105;
    padding: 20px 15px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    transition: .2s ease-in-out;
    opacity: 1;
    // box-shadow: 0px 0px 30px rgba(0,0,0,0.1);

    .icon-logo {
        font-size: 24px; 
    }

    .dark & {
        // background: #111;

        svg {
            fill: white;
            transition: .4s ease-in-out;

            &:hover {
                fill: white;
            }
        }

        .ham {

            &:hover {
                .line {
                    stroke: white;
                }
            }
            .line {
                stroke: white;
                transition: .4s ease-in-out;
            }
        }

        i {
            color: white;
            text-decoration: none;
            
        }
    }

    .light &, .menu-active & {
        background: white;
    }

    .menu-active & {
        svg {
            fill: $greyBG;

            &:hover {
                fill: black;
            }
        }

        .ham {
            .line {
                stroke: $greyBG;
            }

            &:hover {
                .line {
                    stroke: black;
                }
            }
        }

        i {
            color: $greyBG;
            text-decoration: none;

            &:hover {
                color: black;
            }
        }
    }

    svg {
        fill: $greyBG;
        max-width: 60px;
        width: 100%;
        transition: .4s ease-in-out;
    }

    i {
        color: $greyBG;
        transition: .4s ease-in-out;
    }

    .ham {
        width: 70px;

        .line {
            stroke: $greyBG;
        }
    }

    .hidden & {
        opacity: 0;
    }

    .menu-open & {
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -o-transform: translateX(0px);
    }
}

.menu_wrapper {
    position: fixed;
    min-height: 100vh;
    width: calc(50vw - 70px);
    background: white;
    z-index: -1;
    top: 0;
    left: 70px;
    opacity: 1;
    padding: 40px;
    transform: translateX(-100%);
    -o-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transition: border-radius .2s ease-in-out, .3s width .1s ease-in-out, .3s transform .1s ease-in-out;
    border-radius: 90%;

    .menu-active & {
        z-index: 105;
        transform: translateX(0%);
        -o-transform: translateX(0%);
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        border-radius: 0%;
        width: calc(100vw - 70px);
    }

    &.dark {
        background: #111;
    }
}

.menu_item_wrapper {
    display: flex;
    flex-direction: column;
    @include center;
    position: absolute;
    text-align: center;
    left: calc(50% - 70px);
    color: $greyBG;
    transition: .2s ease-in-out;

    a {
        color: $greyBG;
    }

    h3 {
        padding-bottom: 10px;
        margin-bottom: 50px;
        transition-timing-function: ease;
        transition: transform .35s;
        display: inline-block;

        &:hover {
            color: black;

            &:after {
                transform: scaleX(1);
                transform-origin: 0 50%;
                transition-timing-function: ease;
                background: black;
            }
        }

        &:after {
            width: 100%;
            transform: scaleX(0);
            transform-origin: 100% 50%;
            transition-timing-function: ease;
            transition: transform .35s;
            transition-timing-function: cubic-bezier(.25,.46,.45,.94);
            height: 4px;
        }
    }


    ul {
        display: none;
    }
}

.menu_item {
    cursor: pointer;
    transform: translateY(10px);
    -o-transform: translateY(10px);
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    opacity: 0;
    transition: .2s ease-in-out;

    
    ul {
        margin-top: -10px;

        a {
            color: rgba(black, 0.5);
            text-decoration: none;
            transition: .2s ease-in-out;
            cursor: pointer;

            &:hover {
                color: #000;
            }
        }

        li {
            font-size: 16px;
            color: rgba(black, 0.5);
            margin-bottom: 30px;
            text-transform: uppercase;
        }
    }

    .menu-active & {
        transition-delay: .4s;
        -ms-transition-delay: .4s;
        -o-transition-delay: .4s;
        -webkit-transition-delay: .5s;
        opacity: 1;
        transform: translateY(0px);
        -o-transform: translateY(0px);
        -webkit-transform: translateY(0px);
        -ms-transform: translateY(0px);

        &:nth-child(2) {
            transition-delay: .6s;
            -ms-transition-delay: .6s;
            -o-transition-delay: .6s;
            -webkit-transition-delay: .6s;
        }

        &:nth-child(3) {
            transition-delay: .7s;
            -ms-transition-delay: .7s;
            -o-transition-delay: .7s;
            -webkit-transition-delay: .7s;
        }

        &:nth-child(4) {
            transition-delay: .8s;
            -ms-transition-delay: .8s;
            -o-transition-delay: .8s;
            -webkit-transition-delay: .8s;
        }

        &.inactive {
            display: none;
        }
    
    }

    &.active {
        ul {
            display: block;
        }
    }
}

.menu-item-list {
    width: 100%;
    display: flex;

    ul {
        &:first-child {
            margin-right: 120px;
        }
    }
}

#chiller-trigger img, #lorax-trigger img {
    max-width: 140px;
}

#pedini-trigger img { 
    max-width: 160px;
}

#webby-trigger img {
    max-width: 180px;
}

#favela-trigger img {
    max-width: 230px;
}

#softheon-trigger img, #parotees-trigger img, #biggest-loser-trigger img, #deepak-trigger img {
    max-width: 190px;
}

#ahalife-trigger img {
    max-width: 110px;
}

.icon-mail {
    color: #444;
    font-size: 24px;
    transition: .4s ease-in-out;
    cursor: pointer;

    &:hover {
        color: white;
    }
}

@keyframes inactiveItem {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    99% {
        opacity: 0;
        transform: scale(0.4);
    }
    100% {
        display: none;
    }
}