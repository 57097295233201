* {
	box-sizing: border-box;
}

a {
	color: white;
	text-decoration: none;
}

body {
	background: white;
	font-family: 'Roboto', sans-serif;
	position: relative;
	overflow-x: hidden;
	min-height: 100vh;
	width: 100vw;
	color: $greyBG;
}

.browser-detection-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 110;
  color: white;
  text-align: center;
	display: none; 
	
	.message-heading {
		font-size: 30px;
		line-height: 34px;
		margin-bottom: 10px;
	}

  .message-subheading {
    font-size: 16px;
    line-height: 34px;
		margin-bottom: 40px; 
	}

  &:after {
    width: 100vw;
    height: 100vh;
    background: black;
    opacity: .8;
    content: '';
    top: 0;
    left: 0;
    position: absolute;
		z-index: -1; 
	}
}

.browser-content-wrapper {
  max-width: 600px;
  width: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute; 
}

.browser-type-wrapper {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center; 

	a {
		margin: 0 30px;

		i {
			font-size: 50px;
			opacity: 0.8;
			-o-transition: .4s ease-in-out;
			-ms-transition: .4s ease-in-out;
			-webkit-transition: .4s ease-in-out;
			transition: .4s ease-in-out;
			-o-transform: translateY(0);
			-ms-transform: translateY(0);
			-webkit-transform: translateY(0);
			transform: translateY(0); 
			display: inline-block;
		}

    &:hover {
			i {
				opacity: 1;
				-o-transform: translateY(-10px);
				-ms-transform: translateY(-10px);
				-webkit-transform: translateY(-10px);
				transform: translateY(-10px); 
			}
		}
	}	
}

::-webkit-scrollbar {
	width: 0px;
	background: transparent; /* make scrollbar transparent */
}

.no-scroll {
	overflow: hidden;
}

img {
	width: 100%;
	height: auto;
}

.close {
	position: absolute;
	top: 25px;
	right: 30px;
	height: 40px;
	width: 40px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	&:after, &:before {
		position: absolute;
		background: white;
		width: 60%;
    height: 4px;
    content: '';
		border-radius: 1px;
		transition: .2s ease-in-out;
		-moz-transition: .2s ease-in-out;
		-webkit-transition: .2s ease-in-out;
		-o-transition: .2s ease-in-out;
	}

	&:after {
		transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		-o-transform: rotate(45deg);
	}

	&:before {
		transform: rotate(-45deg);
		-moz-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
	} 

	&:hover {
		&:after, &:before {
			transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-webkit-transform: rotate(0deg);
			-o-transform: rotate(0deg);
		}
	}
}

.box-shadow {
	box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.08);
}