#details_wrapper {
    position: fixed;
    background: transparent;
    width: 0vw;
    height: 5px;
    @include center;
    z-index: -1;
    overflow-y:scroll;
    overflow-x: hidden;
    background: $chillerRed;

    .outfront & {
        background: $outfront;
    }

    .chiller & {
        background: #0e0e0e;
    }

    .lorax & {
        background: $lorax;
    }

    .details-active & {
        z-index: 3;
        width: 100vw;
        height: 100vh;
        transition: width .4s ease-in-out, height .4s ease-in-out .4s;

        // &:before {
        //     animation: expandInfo .6s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
        // }

        // &:after {
        //     animation: expand .6s cubic-bezier(0.075, 0.82, 0.165, 1) .3s forwards;
        // }
    }

    .details-close & {
        transition: height .4s ease-in-out, width .4s ease-in-out .4s;
        width: 0vw;
        height: 5px;
    }
}

@keyframes expand {
    0% {
        width: 0vw;
        left: 0;
        right: auto;
    }
    50% {
        width: 100vw;
        left: auto;
        right: auto;
    }
    51% {
        width: 100vw;
        left: auto;
        right: 0;
    }
    
    100% {
        width: 0vw;
        left: auto;
        right: 0;
    }
}

@keyframes expandInfo {
    0% {
        width: 0vw;
        left: 0;
        right: auto;
    }
    50% {
        width: 100vw;
        left: auto;
        right: auto;
    }
    51% {
        width: 100vw;
        left: auto;
        right: 0;
    }
    100% {
        width: 40vw;
        left: auto;
        right: 0;
    }
}

.image_wrapper {
    width: 60%;
    
}